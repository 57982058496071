"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_dom_1 = require("react-dom");
require("./styles.scss");
const index_tsx_1 = require("./views/index.tsx");
if (!index_tsx_1.App)
    console.log('lol');
function renderApp() {
    const App = require('./views/index').App;
    react_dom_1.render(React.createElement(App, null), document.getElementById('root'));
}
renderApp();
module['hot'].accept(renderApp);
