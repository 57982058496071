"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Artifact = (artifact) => artifact;
exports.AllArtifacts = [
    Artifact({
        title: 'Void of Nothingness',
        description: 'Nothing happens',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Bow of Hunting',
        description: 'Give this artifact to someone else, that person has three seconds to kill any one person.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Spear of Fury',
        description: 'Reveal your role to everyone. You may now kill ay one person.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Scepter of Rebirth',
        description: 'If this artifact has not been played when you die, you come back to life.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Claw of the Werewolf',
        description: 'You become a Werewolf, keeping any role power you have intact.',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Stone of Alteration',
        description: 'When you play this card you receive a new role',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Pendant of Pride',
        description: 'Choose a player and secretly show them your role.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Citrine of Fortune',
        description: 'Exchange this Artifact for a new one which must be revealed instantly.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Talisman of Trouble',
        description: 'Two players are eliminated during the day that this card is revealed.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Sapphire of Fate',
        description: 'Choose a player with unrevealed artifact and secretly view it. When that player reveals their artifact, both of you resolve it.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Ruby of Kismet',
        description: 'Choose two players and have each of them reveal their artifacts in the order you choose.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Ring of Truth',
        description: 'Choose a player, they must tell you if they are a werewolf.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Wreath of Peace',
        description: 'Never vote to eliminate.',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Mist of Aura',
        description: 'Choose a player they must truthfully say if they are special (not a normal werewolf or villager).',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Mask of Spellcasting',
        description: 'Each day at down choose a player who may not speak that day.',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Vote From Beyond',
        description: 'You may vote during the day, even if you have been eliminated. You may not open your eyes at night when dead.',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Cauldron of Sorcery',
        description: 'Choose a player who must truthfully say if they are a regular villager or not.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Cap of Idiocy',
        description: 'Always vote to eliminate.',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Amulet of Protection',
        description: 'Each day at dawn the holder of the Amulet must give it to a player who has not had it before. The player who has the Amulet cannot be eliminated.',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Sheet of the Ghost',
        description: 'Ask a player who was eliminated the previous day to tell you a single letter.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Blood of the Diseased',
        description: 'Choose a player to become infected with disease. If the werewolves eliminate that player, they do not get to choose a target the following night.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Prism of Power',
        description: 'Choose three players, that night the werewolves must choose one of those three as their target.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Skimmer of the Cursed',
        description: 'When you play this, you are cursed, if a werewolf targets you at night you become a werewolf and join their team.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Orb of Speculation',
        description: 'Choose two players. If both of them are werewolves the village team wins and the game is over. If they are not, you are instantly eliminated.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Amber of Dawn',
        description: 'All players who have revealed 1 use artifacts receive new artifacts.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Emerald of Jealousy',
        description: 'Choose a player with an unrevealed artifact card. Take that card from them, it is yours now.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Onyx of Destruction',
        description: 'Choose a player with an unrevealed artifact card and remove it from the game.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Staff of the Seer',
        description: 'Choose a player and secretly view their role.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Diary of the Insomniac',
        description: 'You are told if either of your neighbors woke up the previous night.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Amethyst of Knowledge',
        description: 'Choose a player with an unrevealed artifact and secretly view it. Decide if you want that player to reveal that artifact now.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Coin of Youth',
        description: 'You must say the name of your role at least once per day or you will be eliminated that night.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Eye of the Beholder',
        description: 'Each night wake with the seer.',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Shroud of Shame',
        description: 'Each day at dawn choose a player who must face away from the rest of the players.',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Brand of the villager',
        description: 'Choose a player who loses any special roles (but retains their team) permanently.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Cloak of the Prince',
        description: 'You may not be eliminated during the day.',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Heart of Indifference',
        description: 'Each day at dawn choose a player who may not vote.',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Cudgel of the Old Hag',
        description: 'Each day at dawn choose a player to leave the village during the day',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Breath of the Old Man',
        description: 'Choose a player to be eliminated the night after the next day.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Rod of Reincarnation',
        description: 'If you are eliminated, reveal your artifact and stay in the game but receive a new role.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Make of the Mayor',
        description: 'Your vote counts as two votes when voting to eliminate players',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Diamond of Denial',
        description: 'Choose a player with a revealed infinite artifact and take that card from them returning it to the deck.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Flask of Dreams',
        description: 'Choose a player to lose his special power for two days.',
        infinite: false,
        activated: false,
    }),
    Artifact({
        title: 'Mirror of the Doppleganger',
        description: 'Choose a player and secretly view their role. You now have that players special power in addition to yours.',
        infinite: true,
        activated: false,
    }),
    Artifact({
        title: 'Shield of the Bodyguard',
        description: 'Each day at dawn choose a different player who cannot be eliminated that night.',
        infinite: true,
        activated: false,
    }),
];
