"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function makeSetupPlayer(name, claimed = false) {
    return {
        name: name,
        pin: null,
        role: null,
        artifact: null,
        claimed: claimed,
        remoteSetup: null,
    };
}
exports.makeSetupPlayer = makeSetupPlayer;
exports.defaultPlayer = Object.assign({}, makeSetupPlayer(''), { alive: true, links: null, copiedBy: null, protected: false, bitten: false, blessed: false, indoctrinated: false, role: 'villager', originalRole: null, silenced: false, exiled: false, drunk: false, betOn: false });
