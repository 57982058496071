"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const firebase_ts_1 = require("../../../helpers/firebase.ts");
const layout_tsx_1 = require("../../../components/layout.tsx");
const input_tsx_1 = require("../../../components/input.tsx");
class Chat extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { comment: '' };
    }
    render() {
        const { playerName, game } = this.props;
        return (React.createElement(layout_tsx_1.Content, { className: "chat" },
            React.createElement("h1", null, "Chat"),
            React.createElement(input_tsx_1.Input, { label: "Message:", id: "chat-input", value: this.state.comment, onChange: e => this.setState({ comment: e.target.value }), onSubmit: e => {
                    const comment = e.target.value.trim();
                    if (!comment)
                        return;
                    this.setState({ comment: '' });
                    firebase_ts_1.updateFirebase({
                        game: Object.assign({}, game, { chat: [
                                {
                                    playerName,
                                    comment,
                                },
                                ...(game.chat || []),
                            ] }),
                    });
                } }),
            React.createElement("ul", null, (game.chat || []).map((chat, i) => (React.createElement("li", { className: "comment", key: i },
                React.createElement("strong", { className: "player-name" },
                    chat.playerName,
                    ":"),
                chat.comment))))));
    }
}
exports.Chat = Chat;
