"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const cx = require("classnames");
const ramda_1 = require("ramda");
const tabs_tsx_1 = require("../../../components/tabs.tsx");
const button_tsx_1 = require("../../../components/button.tsx");
const layout_tsx_1 = require("../../../components/layout.tsx");
const observe_tsx_1 = require("./observe.tsx");
const chat_tsx_1 = require("./chat.tsx");
const proveDead_1 = require("./proveDead");
const ghost_tsx_1 = require("./ghost.tsx");
const index_ts_1 = require("../../../helpers/index.ts");
const local = require("../../../helpers/localstorage.ts");
class Spectate extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { view: 'overview' };
    }
    render() {
        const { game } = this.props;
        const loggedInDeadPlayers = game.loggedInDeadPlayers || {};
        const deadCredentials = local.deadUsername.get();
        if (!deadCredentials)
            return React.createElement("div", null, "whoops");
        const isUserLoggedIn = loggedInDeadPlayers[deadCredentials.playerName];
        const isCorrectGame = deadCredentials.passcode === game.passcode;
        return (React.createElement(layout_tsx_1.Content, { className: "spectate day" },
            React.createElement(tabs_tsx_1.Tabs, { navigation: true, className: "stats" },
                React.createElement("div", null,
                    "Living: ",
                    ramda_1.values(game.players).filter(p => p.alive).length),
                React.createElement("div", null,
                    "Dead: ",
                    ramda_1.values(game.players).filter(p => !p.alive).length),
                React.createElement(button_tsx_1.Button, { className: "spectate-tab-button", onClick: () => this.setState({ view: 'overview' }) },
                    "\uD83D\uDC40",
                    React.createElement("h3", null, "overview")),
                game.options.ghost && (React.createElement(button_tsx_1.Button, { className: "spectate-tab-button", onClick: () => this.setState({ view: 'ghost' }) },
                    "\uD83D\uDC7B",
                    React.createElement("h3", { className: cx({ red: index_ts_1.isNight(game) }) }, "ghost"))),
                React.createElement(button_tsx_1.Button, { className: "spectate-tab-button", onClick: () => this.setState({ view: 'chat' }) },
                    "\uD83D\uDE4A",
                    React.createElement("h3", null, "chat"))),
            this.state.view === 'overview' && (React.createElement(observe_tsx_1.Overview, { moderate: this.props.moderate, leaveLobby: this.props.leaveLobby, game: this.props.game })),
            this.state.view !== 'overview' &&
                (!isUserLoggedIn || !isCorrectGame ? (React.createElement(proveDead_1.ProveDead, { game: game })) : this.state.view === 'chat' ? (React.createElement(chat_tsx_1.Chat, { game: this.props.game, playerName: deadCredentials.playerName })) : this.state.view === 'ghost' ? (React.createElement(ghost_tsx_1.Ghost, { game: this.props.game })) : null)));
    }
}
exports.Spectate = Spectate;
