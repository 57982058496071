"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const input_tsx_1 = require("../components/input.tsx");
const firebase_ts_1 = require("../helpers/firebase.ts");
const layout_tsx_1 = require("../components/layout.tsx");
const tabs_tsx_1 = require("../components/tabs.tsx");
const button_tsx_1 = require("../components/button.tsx");
class Options extends React.Component {
    render() {
        return (React.createElement(layout_tsx_1.Content, null,
            React.createElement(input_tsx_1.Input, { id: "time-limit", type: "tel", value: this.props.timeLimit, label: "Day Timer:", onChange: e => firebase_ts_1.updateFirebase({ timeLimit: parseInt(e.target.value || '0', 10) }) }),
            React.createElement(input_tsx_1.Input, { id: "no-flip", type: "checkbox", checked: !!this.props.noFlip, label: "No Flip:", onChange: e => {
                    firebase_ts_1.updateFirebase({ noFlip: !!e.target.checked });
                } }),
            React.createElement(input_tsx_1.Input, { id: "ghost", type: "checkbox", checked: !!this.props.ghost, label: "Ghost:", onChange: e => {
                    firebase_ts_1.updateFirebase({ ghost: !!e.target.checked });
                } }),
            React.createElement(input_tsx_1.Input, { id: "kill-cult", type: "checkbox", checked: !!this.props.killCult, label: "Kill Cult:", onChange: e => {
                    firebase_ts_1.updateFirebase({ killCult: !!e.target.checked });
                } }),
            React.createElement(input_tsx_1.Input, { id: "boogyman", type: "checkbox", checked: !!this.props.boogymanOP, label: "Boogyman wakes up for every role:", onChange: e => {
                    firebase_ts_1.updateFirebase({ boogymanOP: !!e.target.checked });
                } }),
            React.createElement(tabs_tsx_1.Tabs, { actions: true },
                React.createElement(button_tsx_1.Button, { confirm: true, className: "red", onClick: () => firebase_ts_1.updateFirebase({
                        timeLimit: 120,
                        noFlip: false,
                        ghost: false,
                        killCult: false,
                    }) }, "reset options"))));
    }
}
exports.Options = Options;
