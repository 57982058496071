"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Card = (card) => card;
exports.AllCards = [
    Card({
        role: 'villager',
        team: 'villager',
        description: 'You are job is to find the werewolves and kill them',
        hints: [],
        weight: 1,
        cardCount: 10,
        emoji: '👩‍🌾',
        image: require('../assets/villager.png'),
        profile: require('../assets/villager-profile.png'),
    }),
    Card({
        role: 'werewolf',
        team: 'wolf',
        description: `You are a werewolf, Kill everyone who's not a werewolf.`,
        hints: [
            'Never say you are bad.',
            'Claiming lycan works sometimes if the lycan is in the game.',
            'If a handful of people are eliminated already, try claiming seer or apprentice seer.',
        ],
        weight: -6,
        cardCount: 3,
        emoji: '🐺',
        image: require('../assets/werewolf.png'),
        profile: require('../assets/werewolf-profile.png'),
    }),
    Card({
        role: 'tanner',
        cardCount: 1,
        emoji: '😭',
        team: 'tanner',
        weight: -2,
        description: 'You only win if you are voted to be killed during the day.',
        hints: [
            `You want to act suspicious, but not too suspicious.`,
            `You can claim any role, even if a person comes out against you it works out.`,
            `Always claim to be good.`,
        ],
        deathMessage: 'if the tanner was lynched then they win',
        image: require('../assets/tanner.png'),
        profile: require('../assets/tanner-profile.png'),
    }),
    Card({
        role: 'big bad wolf',
        weight: -9,
        team: 'wolf',
        description: `You're a Werewolf. As long as you're alive, your team eliminates two adjacent players each night.`,
        hints: [
            `Be careful not to paint yourselves into a corner, the targest must be adjacent (excluding eliminated players). You can easily end up kill each other.`,
            `You are more important than other wolves, try to stay in the game as long as you can.`,
        ],
        emoji: '🐗',
        cardCount: 1,
        image: require('../assets/big-bad-wolf.png'),
        profile: require('../assets/big-bad-wolf-profile.png'),
    }),
    Card({
        role: 'wolf cub',
        weight: -8,
        team: 'wolf',
        emoji: '🐶',
        cardCount: 1,
        description: `You're a Werewolf. When you die your team gets to kill two people.`,
        hints: [
            'Your value happens when you die, you do not want to be the only wolf alive.',
            `Act suspicious, try coming out as the seer or aprentice seer a few rounds into the game. You may find out who the real seer is and your team will be able to kill two people when you die.`,
        ],
        deathMessage: 'the wolf cub died, wolves get to kill two people next night',
        image: require('../assets/wolf-cub.png'),
        profile: require('../assets/wolf-cub-profile.png'),
    }),
    Card({
        role: 'seer',
        weight: 7,
        team: 'villager',
        emoji: '🔮',
        cardCount: 1,
        description: `Each night, you can inspect a player and find out if they're on the Werewolf team or not.`,
        hints: [
            `If the tanner is in the game, you want to make sure the villagers don't vote to eliminate the tanner`,
            `If the bodyguard is in the game, it is typically safe to come out earlier and ask to be protected.`,
            `If there is no way for you to be protected, you may want to wait until you find more than one werewolf.`,
            `Chewkacabra shows up as a villager until all the werewolves are dead.`,
            `Cursed shows up as a villager until they are attacked by werewolves.`,
            `Lycan always shows up as a werewolf even though they are a villager.`,
        ],
        nightMessage: 'seer, inspect someone',
        image: require('../assets/seer.png'),
        profile: require('../assets/seer-profile.png'),
    }),
    Card({
        role: 'apprentice seer',
        weight: 4,
        team: 'villager',
        emoji: '🧖‍',
        cardCount: 1,
        description: `You become the Seer if they're eliminated. Each night, you get to wake up and inspect if someone is on the werewolf team or not.`,
        hints: [],
        nightMessage: 'apprentice seer, inspect someone',
        image: require('../assets/apprentice-seer.png'),
        profile: require('../assets/apprentice-seer-profile.png'),
    }),
    Card({
        role: 'bodyguard',
        weight: 3,
        team: 'villager',
        emoji: '👮‍♀️',
        cardCount: 1,
        description: `Each night, choose a player who cannot be killed that night.`,
        hints: [
            `Pick yourself to start, if the Seer reveals themselves, start picking them, unless the werewolves know you're the body guard, then protect yourself.`,
        ],
        nightMessage: 'bodyguard, protect someone',
        image: require('../assets/bodyguard.png'),
        profile: require('../assets/bodyguard-profile.png'),
    }),
    Card({
        role: 'hunter',
        weight: 3,
        team: 'villager',
        emoji: '🏹',
        cardCount: 1,
        description: `When you die, you immediately pick a player who also dies.`,
        hints: [`If you don't know a werewolf, kill someone for the lulz.`],
        deathMessage: 'the hunter has died, choose a player to kill',
        image: require('../assets/hunter.png'),
        profile: require('../assets/hunter-profile.png'),
    }),
    Card({
        role: 'cupid',
        weight: -3,
        team: 'villager',
        emoji: '🏹',
        cardCount: 1,
        description: `You link two players the first night, and if one of them dies the other dies too.`,
        hints: [
            `Never reveal who you linked, unless you know one of them to be a werewolf`,
        ],
        image: require('../assets/cupid.png'),
        profile: require('../assets/cupid-profile.png'),
    }),
    Card({
        role: 'cursed',
        weight: -3,
        team: 'villager',
        emoji: '🧟‍',
        cardCount: 1,
        actions: ['transform'],
        description: `You are a regular villager until the werewolves try to kill you at night, then you transform into a werewolf and wake up with them.`,
        hints: [`Try really hard to get the werewolves to kill you.`],
        preDeathAction: player => ({
            target: player.name,
            message: `${player.name} is the cursed, what would you like to do?`,
            actions: ['transform', 'sudo kill'],
        }),
        image: require('../assets/cursed.png'),
        profile: require('../assets/cursed-profile.png'),
    }),
    Card({
        role: 'mason',
        weight: 2,
        team: 'mason',
        emoji: '👁',
        cardCount: 3,
        description: `You are in a secret society and know who the other masons are.`,
        hints: [
            `Don't say the word Mason, or reference any sort of secret club. If you do, you die that night.`,
            `Try to use your knowledge of knowing other good people to help guide others in the right direction.`,
        ],
        image: require('../assets/mason.png'),
        profile: require('../assets/mason-profile.png'),
    }),
    Card({
        role: 'witch',
        weight: 4,
        team: 'villager',
        emoji: '🧙‍♂️',
        cardCount: 1,
        description: `At night, once per game (each) you can 1) choose to protect everyone 2) choose to kill someone.`,
        hints: [
            `Save your protection for a time that would be helpful to the team, i.e. if the Seer reveals themselves then save everyone that night. Or if the Wolf Cub is in the game then save everyone the night there werewolves get to kill 2 people.`,
            `Your save becomes more valuable as the game keeps going, don't die without using it!`,
        ],
        nightMessage: 'witch, thumbs up to save everyone, thumbs down and point to kill someone',
        image: require('../assets/witch.png'),
        profile: require('../assets/witch-profile.png'),
    }),
    Card({
        role: 'doppleganger',
        weight: -2,
        team: 'villager',
        emoji: '🤷‍♀️',
        cardCount: 1,
        description: `You pick a player the first night. When that player dies, you become that role.`,
        hints: [
            `Usually the moderator will try to tap you to tell you your role after you become that role. If you are playing on your phone, your role will update and show you.`,
            `If the moderator does not mention anything, chances are you are a villager or non-important role.`,
            `Be careful about coming out too early with who you cloned, it can get you into trouble if they were suspicious in throughout the game.`,
            `You can lie and say you cloned someone who was clearly good (and dead).`,
        ],
        image: require('../assets/doppleganger.png'),
        profile: require('../assets/doppleganger-profile.png'),
    }),
    Card({
        role: 'drunk',
        weight: 4,
        team: 'villager',
        emoji: '🍺',
        cardCount: 1,
        description: `You are a villager until the third night. You then find out your real role.`,
        hints: [`gl;hf`],
        image: require('../assets/drunk.png'),
        profile: require('../assets/drunk-profile.png'),
    }),
    Card({
        role: 'sorceress',
        weight: -3,
        team: 'minion',
        emoji: '🧙‍♀️',
        cardCount: 1,
        description: `You're on the Werewolf team but apear as a villager. You don't know who anyone on the Werewolf team is. Each night, you can pick someone and you're told if they're the Seer.`,
        hints: [
            `After the first or second night, try coming out as the Seer and claiming someone else is bad. The real Seer may come out to contradict you.`,
            `Your job is to help the Werewolves find the seer. Try acting very suspicious to draw the attention away from the Werewolves.`,
        ],
        nightMessage: 'sorceress, look for the seer',
        image: require('../assets/sorceress.png'),
        profile: require('../assets/sorceress-profile.png'),
    }),
    Card({
        role: 'pi',
        weight: 3,
        team: 'villager',
        emoji: '👻',
        cardCount: 1,
        description: `You have a one-time use power where you can inspect a player. You're told if that player or one of their neighbors is a Werewolf.`,
        hints: [
            `This is a very strong power that only grows in importance as the game moves along.`,
            `Try waiting until a few people are in question.`,
        ],
        nightMessage: 'pi, point at some one, if they or one of their neighbors are a wolf I will say yes',
        image: require('../assets/pi.png'),
        profile: require('../assets/pi-profile.png'),
    }),
    Card({
        role: 'prince',
        weight: 3,
        team: 'villager',
        emoji: '🤴',
        cardCount: 1,
        description: `You can't be voted to be eliminated. Reveal your role if you're voted out and stay in the game.`,
        hints: [`You can claim this role early on, nbd.`],
        preDeathAction: player => ({
            target: player.name,
            message: `${player.name} is the prince, what would you like to do?`,
            actions: ['sudo kill'],
        }),
        image: require('../assets/prince.png'),
        profile: require('../assets/prince-profile.png'),
    }),
    Card({
        role: 'lycan',
        weight: -1,
        team: 'villager',
        emoji: '🦊',
        cardCount: 1,
        description: `You are on the villager team but appear like a werewolf to the Seer.`,
        hints: [
            `I'm likin' that.`,
            `It pays to come out as the lycan early in the game. That way others can't claim it.`,
        ],
        image: require('../assets/lycan.png'),
        profile: require('../assets/lycan-profile.png'),
    }),
    Card({
        role: 'aura seer',
        weight: 3,
        team: 'villager',
        emoji: '😇',
        cardCount: 1,
        description: `Each night, you pick a player and are told if they are not a Werewolf or Villager.`,
        hints: [
            `This role is only useful if there are a good number of non-special roles in the game.`,
            `Special wolves such as Big Bad Wolf etc. are all NOT Werewolf roles`,
        ],
        nightMessage: 'aura seer, inspect someone, if they have a special power I will say yes',
        image: require('../assets/aura-seer.png'),
        profile: require('../assets/aura-seer-profile.png'),
    }),
    Card({
        role: 'minion',
        weight: -6,
        team: 'minion',
        emoji: '😈',
        cardCount: 1,
        description: `You know who the Werewolves are, but you don't wake up with them and they don't know who you are.`,
        hints: [
            `Your goal is to cause as much chaos as possible. Just do some crazy things to take the heat off of the wolves.`,
            `Claim important roles like the Seer early on.`,
            `Play your artifacts to cause chaos`,
        ],
        image: require('../assets/minion.png'),
        profile: require('../assets/minion-profile.png'),
    }),
    Card({
        role: 'vampire',
        weight: -7,
        team: 'vampire',
        emoji: '🧛‍♀️',
        cardCount: 3,
        description: `You wake up with other vampires and bite someone. The next day when ANYONE is seconded, the bitten person dies. Vampires are their own team.`,
        hints: [`Seldomly nominate peopel to die, it will draw suspicion to you.`],
        nightMessage: 'vampire, bite someone, when someone stands trial the next die, that person will die',
        image: require('../assets/vampire.png'),
        profile: require('../assets/vampire-profile.png'),
    }),
    Card({
        role: 'mayor',
        weight: 2,
        team: 'villager',
        emoji: '👩🏽‍🎤',
        cardCount: 1,
        description: `Your vote counts as 2.`,
        hints: [
            `Make sure if you ever announce your role that you pronounce it "MARE".`,
        ],
        image: require('../assets/mayor.png'),
        profile: require('../assets/mayor-profile.png'),
    }),
    Card({
        role: 'priest',
        weight: 3,
        team: 'villager',
        emoji: '🙏',
        cardCount: 1,
        description: `You bless a player each night. You can't bless a different player until that person is targeted.`,
        hints: [
            `Bless yourself first, then try to protect a good person with powers after you are targeted.`,
            `Sometimes it can be useful to let the team know who you protected AFTER they have been attacked, it will help the team know who is good.`,
        ],
        nightMessage: 'priest, bless someone. if they are ever killed you will bless another person next night',
        image: require('../assets/priest.png'),
        profile: require('../assets/priest-profile.png'),
    }),
    Card({
        role: 'diseased',
        weight: 3,
        team: 'villager',
        emoji: '🤒',
        cardCount: 1,
        description: `If you are eliminated by Werewolves, they don't get to kill the next night.`,
        hints: [
            `This is a fairly safe role to claim. Although you may not want to as your utility comes when you die.`,
        ],
        deathMessage: 'if the diseased was killed by werewolf the werewolfs can not kill the next night',
        image: require('../assets/diseased.png'),
        profile: require('../assets/diseased-profile.png'),
    }),
    Card({
        role: 'direwolf',
        weight: -4,
        team: 'wolf',
        emoji: '🐩',
        cardCount: 1,
        description: `You're a Werewolf and wake up with them. The first night, you get to pick someone, and you die if they die.`,
        hints: [`Don't pick another werewolf`],
        image: require('../assets/direwolf.png'),
        profile: require('../assets/direwolf-profile.png'),
    }),
    Card({
        role: 'va wolf',
        weight: -2,
        team: 'villager',
        emoji: '👵',
        cardCount: 1,
        description: `You choose someone the first night, if you die that person dies as well.`,
        hints: [
            `As long as your linked partner is in the game DON'T come out. The werewolves will kill you and your linked person.`,
        ],
        image: require('../assets/va-wolf.png'),
        profile: require('../assets/va-wolf-profile.png'),
    }),
    Card({
        role: 'cult leader',
        weight: 1,
        team: 'cult leader',
        emoji: '🍷',
        cardCount: 1,
        description: `Every night wake up to indoctrinate people into your cult. You win if all living players are in your cult.`,
        hints: [
            `Indoctrinate people who you think won't die early`,
            `Your cult dies with you, so DON'T come out as the cult leader. You are a high priority role to kill.`,
        ],
        nightMessage: 'cult leader, indoctrinate someone, they are now part of your cult',
        image: require('../assets/cult-leader.png'),
        profile: require('../assets/cult-leader-profile.png'),
    }),
    Card({
        role: 'fruit brute',
        weight: -3,
        team: 'wolf',
        emoji: '🥕',
        description: `You only eat fruit, so if all the other Werewolves are dead you can't eliminate anyone.`,
        hints: [`GL LOL, just claim vanillager or a good role like lycan.`],
        cardCount: 1,
        image: require('../assets/fruit-brute.png'),
        profile: require('../assets/fruit-brute-profile.png'),
    }),
    Card({
        role: 'fang face',
        weight: -5,
        team: 'wolf',
        emoji: '😸',
        cardCount: 1,
        description: `You only wake up with the Werewolf team on the first night. After that, you'll only wake up if there are no other Werewolves left.`,
        hints: [
            `Remember who the other werewolves are, also moderator will try to tap you to tell you you should wake up when you need to.`,
        ],
        image: require('../assets/fang-face.png'),
        profile: require('../assets/fang-face-profile.png'),
    }),
    Card({
        role: 'spell caster',
        weight: 1,
        team: 'villager',
        emoji: '🧝‍♀️',
        description: `Every night you get to silence someone. That person can't speak the following day.`,
        hints: [`Have fun with it!`],
        cardCount: 1,
        image: require('../assets/spell-caster.png'),
        profile: require('../assets/spell-caster-profile.png'),
    }),
    Card({
        role: 'old hag',
        weight: 1,
        team: 'villager',
        emoji: '👵',
        cardCount: 1,
        description: `Each night, choose a player who must leave the room during the next day. That player cannot be voted on to be eliminated.`,
        hints: [],
        image: require('../assets/old-hag.png'),
        profile: require('../assets/old-hag-profile.png'),
    }),
    Card({
        role: 'village idiot',
        weight: 2,
        team: 'villager',
        emoji: '🤡',
        cardCount: 1,
        description: `You must always vote to eliminate, even yourself.`,
        hints: [],
        image: require('../assets/village-idiot.png'),
        profile: require('../assets/village-idiot-profile.png'),
    }),
    Card({
        role: 'pacifist',
        weight: -1,
        team: 'villager',
        emoji: '✌️',
        cardCount: 1,
        description: `You may never vote to eliminate.`,
        hints: [],
        image: require('../assets/pacifist.png'),
        profile: require('../assets/pacifist-profile.png'),
    }),
    Card({
        role: 'hoodlum',
        weight: 0,
        team: 'villager',
        emoji: '🎰',
        cardCount: 1,
        description: `Choose 2 players on the first night. To win, they must both be dead and you have to still be alive at the end of the game.`,
        hints: [
            `You need to actively try to get those people killed. Nominate them, come out as the role they claim, do whatever you can.`,
        ],
        image: require('../assets/hoodlum.png'),
        profile: require('../assets/hoodlum-profile.png'),
    }),
    Card({
        role: 'chewks',
        weight: 4,
        team: 'chewks',
        emoji: '💆‍♂️',
        cardCount: 1,
        description: `You win if you're the last one left. You get to choose a player to kill each night. You can only kill werewolves to start, but after they're all eliminated you can then target others.`,
        hints: [
            `You appear as a villager to the Seer until all the werewolves are dead. Try to get inspected early in the game to prove your innocence.`,
            `Later in the game you can claim Seer as you should be figuring out who is good and bad.`,
        ],
        nightMessage: `chewks, wake up and choose someone. if they are a werewolf they will die. if they are a villager and no other werewolves are alive, they also die.`,
        image: require('../assets/chewks.png'),
        profile: require('../assets/chewks-profile.png'),
    }),
    Card({
        role: 'bloody marry',
        weight: 1,
        team: 'villager',
        emoji: '💃',
        cardCount: 1,
        description: `You are on the villager team. When you die, you get to wake up while dead in the night phase and try to kill someone. You can only kill people on the team that killed you.`,
        hints: [
            `If you want to be bad, act suspicious, get killed early, and start killing villagers.`,
            `If you want to be good, try to get the werewolves to kill you. Maybe claim a role like Cult leader or VA Wolf`,
        ],
        nightMessage: `bloody marry, if you are dead, wake up. choose someone, if they are on the team that killed you then they die.`,
        image: require('../assets/unknown.png'),
        profile: require('../assets/unknown-profile.png'),
    }),
    Card({
        role: 'wolf man',
        weight: -9,
        team: 'wolf',
        emoji: '🦁',
        cardCount: 1,
        description: `Each night, wake with the other wolves. The seer sees you as a villager.`,
        hints: [
            `Try to get yourself inspected by the seer to claim your innocence`,
            `Artifacts will still reveal your true role`,
        ],
        image: require('../assets/werewolf.png'),
        profile: require('../assets/werewolf-profile.png'),
    }),
    Card({
        role: 'mentalist',
        weight: 6,
        team: 'villager',
        emoji: '🧙‍♂️',
        cardCount: 1,
        nightMessage: `mentalist, you may point at two players, I will tell you if they share a team.`,
        description: `Each night you may point to two players, you are told if those players are on the same team or not.`,
        hints: [
            `You can't select yourself as one of the targets`,
            `You can't chose the same target more than once`,
        ],
        image: require('../assets/unknown.png'),
        profile: require('../assets/unknown-profile.png'),
    }),
    Card({
        role: 'huntress',
        weight: 3,
        team: 'villager',
        emoji: '🏹️',
        cardCount: 1,
        nightMessage: `huntress, once per game you may point at someone to kill them.`,
        description: `You may eliminate a player at night once per game`,
        hints: [
            `Don't wait too long to use your special. The longer you wait the more likely you are to die.`,
        ],
        image: require('../assets/unknown.png'),
        profile: require('../assets/unknown-profile.png'),
    }),
    Card({
        role: 'revealer',
        weight: 4,
        team: 'villager',
        emoji: '✨️',
        cardCount: 1,
        nightMessage: `revealer, wake up. you may point at somone, if they are a wolf they die, otherwise you die.`,
        description: `Each night you may point to a player. If that player is a werewolf, they die, if not, you die.`,
        hints: [`Wait until you are pretty sure someone is suspicious.`],
        image: require('../assets/unknown.png'),
        profile: require('../assets/unknown-profile.png'),
    }),
    Card({
        role: 'teenage werewolf',
        weight: -4,
        team: 'wolf',
        emoji: '🧒',
        cardCount: 1,
        description: `Wake with the other wolfs. You must say "werewolf" or "wolf" at least once per day`,
        hints: [],
        image: require('../assets/werewolf.png'),
        profile: require('../assets/werewolf-profile.png'),
    }),
    Card({
        role: 'alpha wolf',
        weight: -9,
        team: 'wolf',
        emoji: '🐻',
        cardCount: 1,
        description: `Once per game, following the elimination of a wolf during the day, you may turn the wolves target into a wolf instead of eliminating them.`,
        hints: [`Pretty strong, have fun!`],
        image: require('../assets/werewolf.png'),
        profile: require('../assets/werewolf-profile.png'),
    }),
    Card({
        role: 'mad bomber',
        weight: -2,
        team: 'villager',
        emoji: '💣',
        cardCount: 1,
        description: `If you are eliminated, the players immediately to your left and right are eliminated as well.`,
        deathMessage: `The mad bomber has died, players on either side dies as well`,
        hints: [
            `You really don't want to out your role, try as hard as you can to pretend to be something else`,
        ],
        image: require('../assets/unknown.png'),
        profile: require('../assets/unknown-profile.png'),
    }),
    Card({
        role: 'boogyman',
        weight: -6,
        team: 'boogyman',
        emoji: '🦠',
        cardCount: 1,
        description: `Each night wake up after the werewolves. If they can't decide quickly on who to kill, you get to kill someone. When all the werewolves are dead, you can kill people as normal.`,
        hints: [
            `A variant is you get to wake up after each role and perform their action if they can't decide quickly as well`,
        ],
        image: require('../assets/unknown.png'),
        profile: require('../assets/unknown-profile.png'),
    }),
];
exports.Roles = exports.AllCards.map(role => role.role);
exports.getCard = (role) => exports.AllCards.find(r => r.role === role);
