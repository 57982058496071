"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const player_ts_1 = require("../interfaces/player.ts");
const ramda_1 = require("ramda");
const tabs_tsx_1 = require("../components/tabs.tsx");
const input_tsx_1 = require("../components/input.tsx");
const grid_tsx_1 = require("../components/grid.tsx");
const button_tsx_1 = require("../components/button.tsx");
const firebase_ts_1 = require("../helpers/firebase.ts");
const layout_tsx_1 = require("../components/layout.tsx");
const index_ts_1 = require("../helpers/index.ts");
const setupPlayer_tsx_1 = require("../components/setupPlayer.tsx");
class Players extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { playerName: '' };
    }
    render() {
        return (React.createElement(layout_tsx_1.Content, null,
            React.createElement(input_tsx_1.Input, { id: "player-name", value: this.state.playerName, label: "Player name:", onChange: e => this.setState({
                    playerName: e.target.value.replace(/[^a-z]/gi, ''),
                }), onSubmit: () => {
                    if (ramda_1.find(ramda_1.whereEq({ name: this.state.playerName }), this.props.players)) {
                        return alert('Player already exists');
                    }
                    this.setState({
                        playerName: '',
                    });
                    firebase_ts_1.updateFirebase({
                        players: ramda_1.sortBy(player => player.name, [
                            ...this.props.players,
                            player_ts_1.makeSetupPlayer(this.state.playerName),
                        ]),
                    });
                } }),
            React.createElement(grid_tsx_1.Grid, null, this.props.players.sort(index_ts_1.comparePlayersName).map(player => (React.createElement(setupPlayer_tsx_1.PlayerSetupRow, { player: Object.assign({}, player, { role: null }), key: player.name, onClick: () => {
                    firebase_ts_1.updateFirebase({
                        players: ramda_1.remove(ramda_1.findIndex(ramda_1.whereEq({ name: player.name }), this.props.players), 1, this.props.players),
                    });
                } })))),
            React.createElement(tabs_tsx_1.Tabs, { actions: true },
                React.createElement(button_tsx_1.Button, { confirm: true, className: "red", disabled: !this.props.players.length, onClick: () => {
                        this.setState({ playerName: '' });
                        firebase_ts_1.updateFirebase({ players: [] });
                    } }, "reset players"))));
    }
}
exports.Players = Players;
