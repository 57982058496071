"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const cx = require("classnames");
class Timer extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { time: this.props.timeLimit };
        this.timer = null;
        this.interval = 200;
        this.tick = () => {
            this.timer = setTimeout(() => {
                this.tick();
                this.setState({
                    time: Math.max(this.state.time - this.interval / 1000, 0),
                });
                if (!this.state.time) {
                    clearTimeout(this.timer);
                }
            }, this.interval);
        };
    }
    componentDidMount() {
        this.tick();
    }
    componentWillUnmount() {
        clearTimeout(this.timer);
    }
    render() {
        if (this.props.display === 'numbers') {
            return React.createElement("div", { className: "timer numbers" }, Math.floor(this.state.time));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cx('timer bar', this.props.className), style: {
                    width: `${this.state.time / this.props.timeLimit * 100}%`,
                    transition: `width ${this.interval}ms linear`,
                } }),
            React.createElement("div", { className: cx('bar timer-completed', this.props.className), style: {
                    width: `${100 - this.state.time / this.props.timeLimit * 100}%`,
                    transition: `width ${this.interval}ms linear`,
                } })));
    }
}
exports.Timer = Timer;
