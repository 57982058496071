"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const tabs_tsx_1 = require("../../../components/tabs.tsx");
const layout_tsx_1 = require("../../../components/layout.tsx");
const player_ts_1 = require("../../../interfaces/player.ts");
const roles_ts_1 = require("../../../interfaces/roles.ts");
const firebase_ts_1 = require("../../../helpers/firebase.ts");
const index_ts_1 = require("../../../helpers/index.ts");
const local = require("../../../helpers/localstorage.ts");
const game_ts_1 = require("../../../interfaces/game.ts");
const artifacts_ts_1 = require("../../../interfaces/artifacts.ts");
const ramda_1 = require("ramda");
const performSetupAction_1 = require("../performSetupAction");
class RemoteSetup extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {};
        // When we mount, assign everyone a new role and wipe their setup
        // Each new mounting of this component indicates a new game
        this.componentDidMount = () => {
            const roles = index_ts_1.shuffle(this.props.roles);
            const artifacts = this.props.artifacts.length
                ? ramda_1.uniqBy(a => a.title, index_ts_1.shuffle(this.props.artifacts).concat(index_ts_1.shuffle(artifacts_ts_1.AllArtifacts))).slice(0, roles.length)
                : [];
            // loop over each player and assign them a role and a setup action
            firebase_ts_1.updateFirebase({
                players: this.props.players.map((player, i) => {
                    const role = roles[i];
                    const artifact = artifacts[i] || null;
                    return Object.assign({}, player, { role,
                        artifact, remoteSetup: {
                            targets: [],
                            players: [],
                            done: false,
                        } });
                }),
            });
        };
        this.render = () => {
            const isReady = this.props.players.reduce((memo, player) => memo && !!(player.remoteSetup && player.remoteSetup.done), true);
            return (React.createElement(layout_tsx_1.Content, null,
                React.createElement(tabs_tsx_1.Tabs, { navigation: true },
                    React.createElement("div", null, "players")),
                React.createElement("div", { className: "padded" }, this.props.players.map(player => (React.createElement("div", { key: player.name },
                    React.createElement("div", null,
                        player.remoteSetup && player.remoteSetup.done ? '🔵' : '🔴',
                        React.createElement("strong", null, player.name),
                        ": ",
                        React.createElement("span", null, player.role)))))),
                React.createElement(tabs_tsx_1.Tabs, { actions: true },
                    React.createElement("button", { onClick: this.props.onCancel, className: "red" }, "cancel"),
                    React.createElement("button", { onClick: () => {
                            const passcode = Math.floor(Math.random() * 10000).toString();
                            local.passCode.set(passcode);
                            // Assign drunk
                            const randomRole = roles_ts_1.Roles[Math.floor(Math.random() * roles_ts_1.Roles.length)];
                            const players = this.props.players
                                .map(player => player.role === 'drunk'
                                ? Object.assign({}, player, { role: randomRole, drunk: true }) : player)
                                .reduce((memo, player) => (Object.assign({}, memo, { [player.name]: Object.assign({}, player_ts_1.defaultPlayer, player) })), {});
                            const actions = this.props.players.reduce((memo, p) => p.remoteSetup
                                ? memo.concat(Object.assign({}, p.remoteSetup, { players: [p.name], role: p.role || 'villager', requiredPlayers: 0, requiredTargets: 0 }))
                                : memo, []);
                            const setupPlayers = actions.reduce((memo, action) => performSetupAction_1.performSetupAction(memo, action), players);
                            let game = Object.assign({}, game_ts_1.defaultGame, { passcode, initialRoles: this.props.allRoles, players: setupPlayers, options: {
                                    timeLimit: this.props.timeLimit,
                                    noFlip: this.props.noFlip,
                                    ghost: this.props.ghost,
                                    killCult: this.props.killCult,
                                    boogymanOP: this.props.boogymanOP,
                                } });
                            firebase_ts_1.updateFirebase({ game });
                        }, disabled: !isReady }, "start game"))));
        };
    }
}
exports.RemoteSetup = RemoteSetup;
