"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const tabs_tsx_1 = require("../../components/tabs.tsx");
const index_ts_1 = require("../../helpers/index.ts");
const buttons_1 = require("./buttons");
const button_tsx_1 = require("../../components/button.tsx");
exports.PromptView = ({ prompt: { actions = [], message, className, target, nightPrompt }, game, done, }) => (React.createElement("div", { className: "prompt" },
    React.createElement("h1", { className: className }, message),
    React.createElement(tabs_tsx_1.Tabs, null,
        !nightPrompt && (React.createElement(button_tsx_1.Button, { className: "red", onClick: () => {
                done(index_ts_1.removePrompt(game, message));
            } }, "dismiss")),
        actions.map(type => buttons_1.makeActionButton(game, target ? game.players[target] : null, type, game => done(index_ts_1.removePrompt(game, message)))))));
