"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const cx = require("classnames");
const players_tsx_1 = require("./players.tsx");
const index_tsx_1 = require("./deck/index.tsx");
const index_tsx_2 = require("./setup/index.tsx");
const index_ts_1 = require("../helpers/index.ts");
const tabs_tsx_1 = require("../components/tabs.tsx");
const overview_tsx_1 = require("./overview.tsx");
const weight_tsx_1 = require("../components/weight.tsx");
const index_tsx_3 = require("./game/index.tsx");
const firebase_ts_1 = require("../helpers/firebase.ts");
const options_tsx_1 = require("./options.tsx");
const index_tsx_4 = require("./game/spectate/index.tsx");
const roles_ts_1 = require("../interfaces/roles.ts");
const mainMenu_tsx_1 = require("./mainMenu.tsx");
const local = require("../helpers/localstorage.ts");
const lobbyIndividual_1 = require("./lobbyIndividual");
const artifacts_1 = require("./artifacts");
const initialLobbyId = local.lobbyId.get();
firebase_ts_1.setLobby(initialLobbyId);
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, firebase_ts_1.defaultFirebaseState, { view: 'menu', lobbyId: initialLobbyId });
        this.listenToFirebase = (id) => {
            firebase_ts_1.database.ref(id).on('value', snapshot => {
                this.setState(snapshot ? Object.assign({}, firebase_ts_1.defaultFirebaseState, snapshot.val()) : {});
            });
        };
        if (initialLobbyId) {
            this.listenToFirebase(initialLobbyId);
        }
    }
    componentDidUpdate(prevProps, prevState) {
        local.lobbyId.set(this.state.lobbyId);
        // We have been kicked from the game, leave the lobby and clear state
        const player = local.player.get();
        if (player) {
            const oldPlayer = prevState.players.find(p => p.name === player.name);
            const newPlayer = this.state.players.find(p => p.name === player.name);
            if (oldPlayer && !newPlayer) {
                local.player.set(null);
                this.setState({ lobbyId: null });
            }
            if (prevState.game && !this.state.game) {
                firebase_ts_1.updateFirebase({
                    players: this.state.players.map(p => p.name === player.name
                        ? Object.assign({}, p, { role: null, done: false, remoteSetup: null }) : p),
                });
            }
        }
        if (this.state.lobbyId !== prevState.lobbyId) {
            if (prevState.lobbyId)
                firebase_ts_1.database.ref(prevState.lobbyId).off();
            if (this.state.lobbyId)
                this.listenToFirebase(this.state.lobbyId);
        }
    }
    render() {
        if (!this.state.lobbyId) {
            return React.createElement(mainMenu_tsx_1.MainMenu, { onJoin: lobbyId => this.setState({ lobbyId }) });
        }
        if (this.state.game && local.passCode.get() === this.state.game.passcode) {
            return React.createElement(index_tsx_3.GameView, { game: this.state.game });
        }
        const localPlayer = local.player.get();
        const player = this.state.players.find(p => !!localPlayer && localPlayer.name === p.name);
        if (player) {
            return (React.createElement(lobbyIndividual_1.IndividualLobby, { game: this.state.game, players: this.state.players, player: player, leaveLobby: () => this.setState({ lobbyId: null }), lobbyId: this.state.lobbyId, roles: this.state.roles }));
        }
        if (this.state.game) {
            return (React.createElement(index_tsx_4.Spectate, { game: this.state.game, leaveLobby: () => this.setState({ lobbyId: null }), moderate: passcode => {
                    local.passCode.set(passcode);
                    this.forceUpdate();
                } }));
        }
        return (React.createElement("div", null,
            React.createElement(tabs_tsx_1.Tabs, { navigation: true },
                React.createElement("button", { className: cx({ active: this.state.view === 'menu' }), onClick: () => this.setState({ view: 'menu' }) },
                    "overview",
                    React.createElement("h3", null,
                        "lobby: ",
                        this.state.lobbyId)),
                React.createElement("button", { className: cx({ active: this.state.view === 'deck' }), onClick: () => this.setState({ view: 'deck' }) },
                    "build deck",
                    React.createElement("h3", null,
                        "cards: ",
                        this.state.roles.length,
                        ", balance:",
                        ' ',
                        React.createElement(weight_tsx_1.Weight, { weight: index_ts_1.getDeckWeight(this.state.roles.map(roles_ts_1.getCard)) }))),
                React.createElement("button", { className: cx({ active: this.state.view === 'artifacts' }), onClick: () => this.setState({ view: 'artifacts' }) },
                    "artifacts",
                    React.createElement("h3", null,
                        "in play: ",
                        this.state.artifacts.length)),
                React.createElement("button", { className: cx({ active: this.state.view === 'players' }), onClick: () => this.setState({ view: 'players' }) },
                    "manage players ",
                    React.createElement("h3", null,
                        "players: ",
                        this.state.players.length)),
                React.createElement("button", { className: cx({ active: this.state.view === 'options' }), onClick: () => this.setState({ view: 'options' }) }, "game options"),
                React.createElement("button", { className: cx({ active: this.state.view === 'setup' }), disabled: !this.state.roles.length ||
                        !this.state.players ||
                        !this.state.players.length, onClick: () => this.setState({ view: 'setup' }) }, "start game")),
            this.state.view === 'menu' && (React.createElement(overview_tsx_1.Overview, { roles: this.state.roles, players: this.state.players, noFlip: this.state.noFlip, timeLimit: this.state.timeLimit, leaveLobby: () => this.setState({ lobbyId: null }), lobbyId: this.state.lobbyId })),
            this.state.view === 'deck' && (React.createElement(index_tsx_1.BuildDeck, { cards: this.state.roles.map(roles_ts_1.getCard), players: this.state.players, previousDecks: this.state.previousDecks })),
            this.state.view === 'artifacts' && (React.createElement(artifacts_1.ManageArtifacts, { artifacts: this.state.artifacts })),
            this.state.view === 'players' && (React.createElement(players_tsx_1.Players, { cards: this.state.roles.map(roles_ts_1.getCard), players: this.state.players })),
            this.state.view === 'options' && (React.createElement(options_tsx_1.Options, { timeLimit: this.state.timeLimit, noFlip: this.state.noFlip, ghost: this.state.ghost, killCult: this.state.killCult, boogymanOP: this.state.boogymanOP })),
            this.state.view === 'setup' && (React.createElement(index_tsx_2.SetupGame, { roles: this.state.roles, artifacts: this.state.artifacts, players: this.state.players, noFlip: this.state.noFlip, timeLimit: this.state.timeLimit, previousDecks: this.state.previousDecks, ghost: this.state.ghost, boogymanOP: this.state.boogymanOP, killCult: this.state.killCult }))));
    }
}
exports.App = App;
