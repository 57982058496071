"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_ts_1 = require("../../helpers/index.ts");
const ramda_1 = require("ramda");
exports.performSetupAction = (_players, action) => {
    let players = Object.assign({}, _players);
    // Updated _all_ of the action's players with the proper roles
    players = action.players.reduce((players, playerName) => index_ts_1.updatePlayerHash(players, playerName, {
        role: action.role,
    }), players);
    // Set the target to be copied by the doppleganger
    if (action.role === 'doppleganger') {
        players = index_ts_1.updatePlayerHash(players, action.targets[0], {
            copiedBy: action.players[0],
        });
    }
    if (action.role === 'hoodlum') {
        players = action.targets.reduce((players, playerName) => index_ts_1.updatePlayerHash(players, playerName, () => ({ betOn: true })), players);
    }
    // Link cupids lovers together
    if (action.role === 'cupid') {
        players = action.targets.reduce((players, playerName) => index_ts_1.updatePlayerHash(players, playerName, ({ links }) => ({
            links: ramda_1.uniq((links || []).concat(ramda_1.reject(ramda_1.equals(playerName), action.targets))),
        })), players);
    }
    // Link the direwolf to its target
    if (action.role === 'direwolf') {
        players = index_ts_1.updatePlayerHash(players, action.targets[0], ({ links }) => ({
            links: ramda_1.uniq((links || []).concat(action.players[0])),
        }));
    }
    // Link the target to va wolf
    if (action.role === 'va wolf') {
        players = index_ts_1.updatePlayerHash(players, action.players[0], ({ links }) => ({
            links: ramda_1.uniq((links || []).concat(action.targets[0])),
        }));
    }
    return players;
};
