"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const helpers_1 = require("../../helpers");
const React = require("react");
const cx = require("classnames");
const ramda_1 = require("ramda");
const game_ts_1 = require("../../interfaces/game.ts");
const tabs_tsx_1 = require("../../components/tabs.tsx");
const player_tsx_1 = require("../../components/player.tsx");
const roles_ts_1 = require("../../interfaces/roles.ts");
const prompt_tsx_1 = require("./prompt.tsx");
const buttons_tsx_1 = require("./buttons.tsx");
const grid_tsx_1 = require("../../components/grid.tsx");
const button_tsx_1 = require("../../components/button.tsx");
const firebase_ts_1 = require("../../helpers/firebase.ts");
const layout_tsx_1 = require("../../components/layout.tsx");
const index_ts_1 = require("../../helpers/index.ts");
const timer_tsx_1 = require("../../components/timer.tsx");
const index_ts_2 = require("../../helpers/index.ts");
class GameView extends React.Component {
    constructor() {
        super(...arguments);
        this.makeWerewolfPrompt = () => {
            const allWolves = index_ts_1.getGameRoles(this.props.game).filter(role => roles_ts_1.getCard(role).team === 'wolf');
            const livingWolves = ramda_1.values(this.props.game.players)
                .filter(player => helpers_1.isPlayerAlive(this.props.game, player.name))
                .filter(player => roles_ts_1.getCard(player.role).team === 'wolf')
                .map(player => player.role);
            const isFangFaceInGame = ramda_1.contains('fang face', allWolves);
            const isAlphaWolfInGame = ramda_1.contains('alpha wolf', allWolves);
            const isBigBadWolfAlive = ramda_1.contains('big bad wolf', livingWolves);
            const isOnlyFangFace = ramda_1.equals(livingWolves, ['fang face']);
            const isOnlyFruitBrute = ramda_1.equals(livingWolves, ['fruit brute']);
            const wasWolfCubKilled = !!(this.props.game.nightKills || []).find(playerName => this.props.game.players[playerName].role === 'wolf cub');
            // prettier-ignore
            const emoji = wasWolfCubKilled ? roles_ts_1.getCard('wolf cub').emoji :
                isBigBadWolfAlive ? roles_ts_1.getCard('big bad wolf').emoji :
                    livingWolves.length > 1 ? roles_ts_1.getCard('werewolf').emoji :
                        !livingWolves.length ? roles_ts_1.getCard('werewolf').emoji :
                            roles_ts_1.getCard(livingWolves[0]).emoji;
            // prettier-ignore
            const wakeUpAndKillPeople = 'wake up and kill ' + (wasWolfCubKilled && isBigBadWolfAlive ? 'three people' :
                wasWolfCubKilled || isBigBadWolfAlive ? 'two people' :
                    'someone');
            // prettier-ignore
            let message = isOnlyFruitBrute ? `fruit brute wake up and eat some fruit` :
                isOnlyFangFace ? `fang face ${wakeUpAndKillPeople}` :
                    isFangFaceInGame ? `werewolves except fang face, ${wakeUpAndKillPeople}` :
                        `werewolves, ${wakeUpAndKillPeople}`;
            let prompts = [
                {
                    nightPrompt: true,
                    message: `${emoji} ${message} ${emoji}`,
                },
            ];
            if (isAlphaWolfInGame &&
                (game_ts_1.isRoleActive(this.props.game, 'alpha wolf') ||
                    this.props.game.options.noFlip))
                prompts.push({
                    message: `alpha wolf wake up, if a werewolf died today (once per game) you may choose to convert the wolves target into a werewolf instead.`,
                    nightPrompt: true,
                });
            return prompts;
        };
        this.startNight = () => {
            if (index_ts_1.isNight(this.props.game))
                return;
            let game = this.props.game;
            const drunkMessage = this.props.game.dayCount === 2
                ? `${roles_ts_1.getCard('drunk').emoji} Drunk sober up, and receive your true identity. You can wake with the werewolves if you are now a werewolf 😏`
                : this.props.game.dayCount > 2
                    ? `${roles_ts_1.getCard('drunk').emoji} Drunk wake up and do something`
                    : ``;
            const drunk = ramda_1.values(game.players).find(player => player.drunk);
            let nightPrompts = index_ts_1.getGameRoles(game)
                .filter(role => !drunk || role !== drunk.role)
                .map(roles_ts_1.getCard)
                .sort((a, b) => b.weight - a.weight)
                .reduce((prompts, card, i) => {
                const active = game_ts_1.isRoleActive(game, card.role);
                return card.nightMessage &&
                    (game.options.noFlip || (!game.options.noFlip && active))
                    ? prompts.concat({
                        message: `${card.emoji} ${card.nightMessage} ${card.emoji}`,
                        className: cx({ dim: !active || game_ts_1.isRoleOmitted(game, card.role) }),
                        nightPrompt: true,
                    })
                    : prompts;
            }, [])
                .concat(!!~game.initialRoles.indexOf('drunk') && drunkMessage
                ? {
                    message: drunkMessage,
                    nightPrompt: true,
                }
                : [])
                .concat(this.makeWerewolfPrompt());
            ramda_1.values(game.players).forEach(player => {
                game = index_ts_2.updatePlayer(game, player.name, { silenced: false });
            });
            if (game_ts_1.isRoleActive(this.props.game, 'boogyman')) {
                if (this.props.game.options.boogymanOP) {
                    nightPrompts = nightPrompts.reduce((memo, prompt) => {
                        const res = prompt.message.split(',');
                        const emoji = prompt.message.split(' ');
                        return [
                            ...memo,
                            prompt,
                            Object.assign({}, prompt, { message: `${emoji[0]} boogyman, ${res.slice(1).join(',')}` }),
                        ];
                    }, []);
                }
                else {
                    const wolfPrompt = nightPrompts[nightPrompts.length - 1];
                    const res = wolfPrompt.message.split(',');
                    const emoji = wolfPrompt.message.split(' ');
                    nightPrompts = nightPrompts.concat({
                        nightPrompt: true,
                        message: `${emoji[0]} boogyman, ${res.slice(1).join(',')}`,
                    });
                }
            }
            firebase_ts_1.updateFirebase({
                game: Object.assign({}, this.props.game, { nightKills: [], nightPrompts: nightPrompts.slice(1), prompts: (this.props.game.prompts || []).concat(nightPrompts[0]), ghost: {} }),
            });
        };
    }
    render() {
        const theLiving = ramda_1.values(this.props.game.players).filter(ramda_1.propEq('alive', true));
        const theLivingWolves = theLiving.filter(p => roles_ts_1.getCard(p.role).team === 'wolf').length;
        const theLivingVampires = theLiving.filter(p => roles_ts_1.getCard(p.role).team === 'vampire').length;
        const theLivingNonWolves = theLiving.length - theLivingWolves - theLivingVampires;
        return (React.createElement(layout_tsx_1.Content, null,
            React.createElement(tabs_tsx_1.Tabs, { navigation: true, className: "stats" },
                React.createElement("div", { className: "game-passcode" },
                    this.props.game.passcode,
                    React.createElement("h3", null, "passcode")),
                React.createElement("div", null,
                    "All Players: ",
                    theLiving.length),
                React.createElement("div", { className: "green" },
                    roles_ts_1.getCard('villager').emoji,
                    ": ",
                    theLivingNonWolves,
                    ' '),
                React.createElement("div", { className: "red" },
                    roles_ts_1.getCard('werewolf').emoji,
                    ": ",
                    theLivingWolves),
                index_ts_1.gameHasRole(this.props.game, 'vampire') && (React.createElement("div", { className: "red" },
                    roles_ts_1.getCard('vampire').emoji,
                    ": ",
                    theLivingVampires)),
                React.createElement(timer_tsx_1.Timer, { key: this.props.game.dayCount, display: "bar", timeLimit: this.props.game.options.timeLimit || 0 })),
            this.props.game.nightKills && (React.createElement("h3", { className: "night-kills" },
                "Killed tonight: ",
                this.props.game.nightKills.join(', '))),
            (this.props.game.prompts || []).map(prompt => (React.createElement(prompt_tsx_1.PromptView, { game: this.props.game, done: game => firebase_ts_1.updateFirebase({ game }), prompt: prompt, key: prompt.message }))),
            React.createElement(grid_tsx_1.Grid, null, ramda_1.values(this.props.game.players)
                .sort(index_ts_1.comparePlayersFull)
                .map(player => (React.createElement(player_tsx_1.PlayerRow, { isActive: this.props.game.activePlayer === player.name, onClick: () => firebase_ts_1.updateFirebase({
                    game: Object.assign({}, this.props.game, { activePlayer: this.props.game.activePlayer === player.name
                            ? null
                            : player.name }),
                }), player: player, key: player.name }, buttons_tsx_1.makeGameButtons(this.props.game, player))))),
            React.createElement(tabs_tsx_1.Tabs, { actions: true },
                React.createElement(button_tsx_1.Button, { confirm: true, className: "red", onClick: () => firebase_ts_1.updateFirebase({ game: null }) }, "end game"),
                React.createElement(button_tsx_1.Button, { onClick: () => !index_ts_1.isNight(this.props.game)
                        ? this.startNight()
                        : firebase_ts_1.updateFirebase({
                            game: game_ts_1.performAction(this.props.game, {
                                type: 'next role',
                                target: null,
                            }),
                        }) }, !(this.props.game.nightPrompts || []).length &&
                    index_ts_1.isNight(this.props.game)
                    ? 'end night'
                    : index_ts_1.isNight(this.props.game)
                        ? 'next role'
                        : 'start night'))));
    }
}
exports.GameView = GameView;
