"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ramda_1 = require("ramda");
const roles_ts_1 = require("../../../interfaces/roles.ts");
const tabs_tsx_1 = require("../../../components/tabs.tsx");
const index_ts_1 = require("../../../helpers/index.ts");
const layout_tsx_1 = require("../../../components/layout.tsx");
const card_tsx_1 = require("../../deck/card.tsx");
const grid_tsx_1 = require("../../../components/grid.tsx");
const weight_tsx_1 = require("../../../components/weight.tsx");
class SelectRoles extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { selectedRoles: this.props.roles };
        this.addRole = (role) => {
            const crntCount = index_ts_1.getNumberOfARole(role, this.state.selectedRoles);
            const max = role === 'villager' ? 10 : index_ts_1.getNumberOfARole(role, this.props.roles);
            if (crntCount + 1 <= max) {
                this.setState({ selectedRoles: this.state.selectedRoles.concat(role) });
            }
            else {
                this.setState({
                    selectedRoles: this.state.selectedRoles.filter(r => r !== role),
                });
            }
        };
    }
    render() {
        const roleCards = ramda_1.uniq(['villager', ...this.props.roles]).map(roles_ts_1.getCard);
        return (React.createElement(layout_tsx_1.Content, null,
            React.createElement(tabs_tsx_1.Tabs, { navigation: true },
                React.createElement("div", null,
                    "players: ",
                    this.props.numberOfPlayers),
                React.createElement("div", { className: this.state.selectedRoles.length !== this.props.numberOfPlayers
                        ? 'red'
                        : '' },
                    "cards: ",
                    this.state.selectedRoles.length),
                React.createElement("div", null,
                    "weight:",
                    React.createElement(weight_tsx_1.Weight, { weight: this.state.selectedRoles.reduce((count, role) => count + roles_ts_1.getCard(role).weight, 0) }))),
            React.createElement("h1", null, "There are unequal roles to players, please modify the roles to match the players."),
            React.createElement(grid_tsx_1.Grid, null, roleCards.map(card => (React.createElement(card_tsx_1.CardRow, { card: card, deck: this.state.selectedRoles.map(roles_ts_1.getCard), onAdd: () => this.addRole(card.role) })))),
            React.createElement(tabs_tsx_1.Tabs, { actions: true },
                React.createElement("button", { className: "red", onClick: this.props.cancel }, "cancel"),
                React.createElement("button", { disabled: this.state.selectedRoles.length !== this.props.numberOfPlayers, onClick: () => this.props.onSubmit(this.state.selectedRoles) }, "done"))));
    }
}
exports.SelectRoles = SelectRoles;
