"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const emptyTarget = '';
exports.Actions = (type) => {
    switch (type) {
        case 'bite':
            return { type: 'bite', playerProp: 'bitten', target: emptyTarget };
        case 'bless':
            return { type: 'bless', playerProp: 'blessed', target: emptyTarget };
        case 'kill':
        case 'sudo kill':
        case 'bypass protection':
            return { type, playerProp: 'alive', target: emptyTarget };
        case 'protect':
            return { type: 'protect', playerProp: 'protected', target: emptyTarget };
        case 'transform':
            return { type: 'transform', playerProp: 'role', target: emptyTarget };
        case 'next role':
            return { type: 'next role', target: null };
        case 'indoctrinate':
            return {
                type: 'indoctrinate',
                playerProp: 'indoctrinated',
                target: emptyTarget,
            };
        case 'silence':
            return { type: 'silence', playerProp: 'silenced', target: emptyTarget };
        case 'exile':
            return { type: 'exile', playerProp: 'exiled', target: emptyTarget };
    }
};
