"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
class Button extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            confirmTimer: null,
        };
        this.onClick = (e) => {
            const propsOnClick = this.props.onClick;
            if (propsOnClick && this.props.confirm && !this.state.confirmTimer) {
                this.setState({
                    confirmTimer: setTimeout(() => {
                        this.setState({ confirmTimer: clearTimeout(this.state.confirmTimer) });
                    }, 3000),
                });
            }
            else if (propsOnClick && this.props.confirm && this.state.confirmTimer) {
                this.setState({ confirmTimer: clearTimeout(this.state.confirmTimer) });
                propsOnClick(e);
            }
            else if (propsOnClick) {
                propsOnClick(e);
            }
        };
    }
    render() {
        const _a = this.props, { confirm, children } = _a, props = __rest(_a, ["confirm", "children"]);
        return (React.createElement("button", Object.assign({}, props, { onClick: this.onClick }), this.state.confirmTimer ? (React.createElement(React.Fragment, null, typeof confirm === 'string' ? confirm : `confirm: ${children}`)) : (children)));
    }
}
exports.Button = Button;
