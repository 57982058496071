"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const cx = require("classnames");
const ramda_1 = require("ramda");
const tabs_tsx_1 = require("../../../components/tabs.tsx");
const button_tsx_1 = require("../../../components/button.tsx");
const firebase_ts_1 = require("../../../helpers/firebase.ts");
const layout_tsx_1 = require("../../../components/layout.tsx");
const index_ts_1 = require("../../../helpers/index.ts");
const timer_tsx_1 = require("../../../components/timer.tsx");
const input_tsx_1 = require("../../../components/input.tsx");
exports.Overview = ({ game, leaveLobby, moderate }) => {
    if (index_ts_1.isNight(game)) {
        return (React.createElement("div", { className: "spectate night" }, ramda_1.values(game.players)
            .filter(player => player.alive)
            .sort()
            .map(player => (React.createElement("div", { key: player.name, className: cx('player', {
                active: game.activePlayer === player.name,
            }) }, player.name)))));
    }
    return (React.createElement(layout_tsx_1.Content, { className: "spectate day" },
        React.createElement(layout_tsx_1.Content, { className: "spectate-body" },
            React.createElement(layout_tsx_1.Content, null,
                React.createElement(timer_tsx_1.Timer, { display: "numbers", timeLimit: game.options.timeLimit || 0 }),
                React.createElement("div", { className: "timer" }, 
                // lol
                (ramda_1.toPairs(ramda_1.filter(arr => !!arr, ramda_1.map(arr => (arr ? Object.keys(arr).length : 0), game.ghost || {}))).sort((a, b) => b[1] - a[1])[0] || [])[0])),
            React.createElement(layout_tsx_1.Content, null,
                React.createElement("h1", null, "Players"),
                ramda_1.values(game.players)
                    .filter(p => p.alive)
                    .map(player => {
                    const role = !game.options.noFlip && !player.alive && `: ${player.role}`;
                    return (React.createElement("div", { key: player.name },
                        player.name,
                        role));
                }),
                ramda_1.values(game.players)
                    .filter(p => !p.alive)
                    .map(player => (React.createElement("div", { key: player.name, className: cx({ dead: !player.alive }) }, player.name)))),
            React.createElement(layout_tsx_1.Content, null,
                React.createElement("h1", null, "Possible Roles"),
                ramda_1.uniq(game.initialRoles).map(role => {
                    const numRole = index_ts_1.getNumberOfARole(role, game.initialRoles);
                    return (React.createElement("div", { key: role },
                        role,
                        numRole > 1 && `: ${numRole}`));
                }))),
        React.createElement(input_tsx_1.Input, { label: "moderate:", id: "moderate", onSubmit: e => moderate(e.target.value), placeholder: "passcode" }),
        React.createElement(tabs_tsx_1.Tabs, { actions: true },
            React.createElement(button_tsx_1.Button, { confirm: true, className: "red", onClick: leaveLobby }, "leave lobby"),
            React.createElement(button_tsx_1.Button, { confirm: true, className: "red", onClick: () => firebase_ts_1.updateFirebase({ game: null }) }, "end game"))));
};
