"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const firebase_ts_1 = require("../helpers/firebase.ts");
const local = require("../helpers/localstorage.ts");
const input_tsx_1 = require("../components/input.tsx");
const player_ts_1 = require("../interfaces/player.ts");
class MainMenu extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            name: '',
            lobbyId: '',
            error: '',
        };
    }
    onSubmit(source) {
        this.setState({ error: '' });
        // Get our error states out of the way
        if (!this.state.lobbyId)
            return this.setState({ error: 'Please specify a lobby' });
        if (source === 'join' && !this.state.name)
            return this.setState({ error: 'Please specify a name' });
        if (source === 'join') {
            firebase_ts_1.getFirebase(this.state.lobbyId).then(lobby => {
                const foundPlayer = lobby.players.find(p => p.name === this.state.name);
                if (foundPlayer && foundPlayer.claimed) {
                    return this.setState({ error: 'Player is already in the game' });
                }
                const player = Object.assign({}, (foundPlayer || player_ts_1.makeSetupPlayer(this.state.name, true)), { claimed: true });
                firebase_ts_1.setLobby(this.state.lobbyId);
                firebase_ts_1.updateFirebase({
                    players: foundPlayer
                        ? lobby.players.map(p => (p.name === this.state.name ? player : p))
                        : lobby.players.concat(player),
                });
                local.player.set(player);
                this.props.onJoin(this.state.lobbyId);
            });
        }
        if (source === 'moderate') {
            this.props.onJoin(this.state.lobbyId);
        }
    }
    render() {
        return (React.createElement("form", { className: "lobby" },
            React.createElement("h1", null, "Welcome to the Werewolf Moderator app."),
            React.createElement(input_tsx_1.Input, { id: "name", label: "Player name:", onChange: e => {
                    this.setState({
                        name: e.target.value
                            .trim()
                            .toLowerCase()
                            .replace(/[^a-z]/gi, ''),
                    });
                } }),
            React.createElement(input_tsx_1.Input, { id: "lobby", label: "Lobby name:", onChange: e => this.setState({
                    lobbyId: e.target.value
                        .trim()
                        .toLowerCase()
                        .replace(/[^a-z]/gi, ''),
                }) }),
            this.state.error && React.createElement("div", { className: "red" }, this.state.error),
            React.createElement("div", { className: "join-buttons" },
                React.createElement("button", { type: "button", onClick: () => this.onSubmit('join') }, "join game"),
                React.createElement("button", { type: "button", onClick: () => this.onSubmit('moderate') }, "moderate game"))));
    }
}
exports.MainMenu = MainMenu;
