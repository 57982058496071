"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const firebase_1 = require("firebase");
const ramda_1 = require("ramda");
firebase_1.default.initializeApp(process.env.NODE_ENV === 'production'
    ? {
        apiKey: 'AIzaSyC2XK6ev0rkTjbX1DEuiUrQb9ohAaJjRYg',
        authDomain: 'wt-werewolf.firebaseapp.com',
        databaseURL: 'https://wt-werewolf.firebaseio.com',
        projectId: 'wt-werewolf',
        storageBucket: 'wt-werewolf.appspot.com',
        messagingSenderId: '529798462395',
    }
    : {
        apiKey: 'AIzaSyBoJ83KQAemXzA6U04NV1I1sRvcDAz1I34',
        authDomain: 'ww-dev-d5d0d.firebaseapp.com',
        databaseURL: 'https://ww-dev-d5d0d.firebaseio.com',
        projectId: 'ww-dev-d5d0d',
        storageBucket: 'ww-dev-d5d0d.appspot.com',
        messagingSenderId: '954677605618',
    });
exports.defaultFirebaseState = {
    roles: [],
    players: [],
    artifacts: [],
    game: null,
    timeLimit: 120,
    noFlip: false,
    ghost: false,
    boogymanOP: false,
    killCult: false,
    previousDecks: [],
};
let lobbyId = null;
exports.setLobby = (id) => (lobbyId = id);
exports.updateFirebase = (props) => {
    if (!lobbyId)
        return Promise.reject(null);
    return exports.database.ref(lobbyId).update(ramda_1.toPairs(props).reduce((acc, [key, val]) => (Object.assign({}, acc, { [`/${key}`]: val })), {}));
};
exports.getFirebase = (lobbyId) => {
    return new Promise(resolve => {
        return exports.database.ref(lobbyId).once('value', snapshot => {
            resolve(snapshot
                ? Object.assign({}, exports.defaultFirebaseState, snapshot.val()) : exports.defaultFirebaseState);
        });
    });
};
exports.ghostVote = (letter) => {
    if (!lobbyId)
        return Promise.reject(null);
    return exports.database.ref(`/${lobbyId}/game/ghost/${letter}`).push(1);
};
exports.database = firebase_1.default.database();
