"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const tabs_tsx_1 = require("../../../components/tabs.tsx");
const layout_tsx_1 = require("../../../components/layout.tsx");
const roles_ts_1 = require("../../../interfaces/roles.ts");
const roleToSetupAction_tsx_1 = require("../roleToSetupAction.tsx");
const index_ts_1 = require("../../../helpers/index.ts");
const grid_tsx_1 = require("../../../components/grid.tsx");
const ramda_1 = require("ramda");
const setupPlayer_tsx_1 = require("../../../components/setupPlayer.tsx");
const firebase_ts_1 = require("../../../helpers/firebase.ts");
const roleDescription_tsx_1 = require("../../../components/roleDescription.tsx");
const artifact_tsx_1 = require("../../../components/artifact.tsx");
class IndividualSetup extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { view: 'hide', localSetup: Object.assign({}, this.props.setup) };
    }
    render() {
        let { targets = [], players = [] } = this.state.localSetup;
        const setupActions = roleToSetupAction_tsx_1.roleToSetupAction(this.props.role, index_ts_1.getNumberOfARole(this.props.role, this.props.roles));
        const playerTeam = roles_ts_1.getCard(this.props.role).team;
        const isMinion = this.props.role === 'minion';
        const shouldShowAllies = playerTeam === 'wolf' ||
            playerTeam === 'vampire' ||
            playerTeam === 'mason' ||
            isMinion;
        const playerAllies = this.props.players.filter(otherPlayer => otherPlayer.name !== this.props.player.name &&
            (otherPlayer.role &&
                ((isMinion && roles_ts_1.getCard(otherPlayer.role).team === 'wolf') ||
                    roles_ts_1.getCard(otherPlayer.role).team === playerTeam)));
        return (React.createElement(layout_tsx_1.Content, { className: "setup-remote" },
            React.createElement(tabs_tsx_1.Tabs, { navigation: true }, this.props.setup.done ? (React.createElement("div", null, "waiting for game to start...")) : (React.createElement("button", { className: "green", onClick: () => {
                    firebase_ts_1.updateFirebase({
                        players: index_ts_1.updateFirst(player => player.name === this.props.player.name, Object.assign({}, this.props.player, { remoteSetup: Object.assign({}, this.state.localSetup, { done: true }) }), this.props.players),
                    });
                }, disabled: this.props.setup.done ||
                    (setupActions.individualMessage &&
                        targets.length !== setupActions.requiredTargets) }, "click this when ready"))),
            this.props.player.artifact && (React.createElement(artifact_tsx_1.ArtifactView, { artifact: this.props.player.artifact })),
            this.state.view === 'hide' ? (React.createElement(React.Fragment, null,
                React.createElement("button", { className: "toggleRole", onClick: () => this.setState({ view: 'show' }) }, "show role"))) : (React.createElement(React.Fragment, null,
                React.createElement("button", { className: "toggleRole", onClick: () => this.setState({ view: 'hide' }) }, "hide role"),
                React.createElement(roleDescription_tsx_1.RoleDescription, { role: this.props.role }),
                shouldShowAllies &&
                    !!playerAllies.length && (React.createElement("div", { className: "padded" },
                    React.createElement("h1", null, "\uD83D\uDC68\u200D\uD83D\uDC68\u200D\uD83D\uDC67\u200D\uD83D\uDC66 Your team: \uD83D\uDC69\u200D\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC66"),
                    playerAllies.map(ally => {
                        if (!ally.role)
                            return null;
                        const action = roleToSetupAction_tsx_1.roleToSetupAction(ally.role, index_ts_1.getNumberOfARole(ally.role, this.props.roles));
                        if (isMinion) {
                            return (React.createElement("div", { key: ally.name },
                                React.createElement("div", null,
                                    React.createElement("strong", null, ally.name),
                                    " is a wolf")));
                        }
                        return (React.createElement("div", { key: ally.name },
                            React.createElement("div", null,
                                React.createElement("strong", null, ally.name),
                                " is a ",
                                ally.role),
                            action.allyMessage &&
                                action.allyMessage(ally.remoteSetup
                                    ? ally.remoteSetup.targets || []
                                    : [])));
                    }))),
                !this.props.setup.done &&
                    setupActions.individualMessage && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "padded" },
                        React.createElement("h1", null, "Choose your targets:"),
                        setupActions.individualMessage(targets)),
                    React.createElement(grid_tsx_1.Grid, null, this.props.players
                        .sort(index_ts_1.comparePlayersName)
                        .filter(p => p.name !== this.props.player.name)
                        .map(otherPlayer => {
                        return (React.createElement(setupPlayer_tsx_1.PlayerSetupRow, { player: {
                                pin: otherPlayer.pin,
                                claimed: otherPlayer.claimed,
                                artifact: otherPlayer.artifact,
                                name: otherPlayer.name,
                                remoteSetup: otherPlayer.remoteSetup,
                                role: null,
                            }, key: otherPlayer.name, onClick: () => {
                                if (!!targets.find(ramda_1.equals(otherPlayer.name))) {
                                    // The player is already a target
                                    targets = ramda_1.reject(ramda_1.equals(otherPlayer.name), targets);
                                }
                                else if (targets.length < setupActions.requiredTargets) {
                                    // There are targets left to be made
                                    targets = ramda_1.append(otherPlayer.name, targets);
                                }
                                this.setState({
                                    localSetup: Object.assign({}, this.state.localSetup, { targets,
                                        players }),
                                });
                            } }));
                    }))))))));
    }
}
exports.IndividualSetup = IndividualSetup;
