"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const cx = require("classnames");
const tabs_1 = require("./tabs");
const roles_ts_1 = require("../interfaces/roles.ts");
exports.PlayerRow = ({ player, children, onClick, isActive, }) => {
    const reminders = (React.createElement("h2", null,
        player.protected && '🛡',
        player.blessed && '🙏',
        player.bitten && '🦇',
        player.indoctrinated && '🍷',
        player.silenced && '🤐',
        player.exiled && '👵',
        player.betOn && '🎲',
        player.drunk && '🍻'));
    const image = roles_ts_1.getCard(player.role).image;
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { onClick: onClick, className: cx('player', { dim: !player.alive }) },
            React.createElement("img", { className: "role-profile", src: image }),
            React.createElement("h2", null, player.name),
            reminders),
        isActive && (React.createElement("div", { className: "modal" },
            React.createElement(tabs_1.Tabs, { navigation: true },
                React.createElement("h1", null,
                    player.name,
                    " ",
                    player.role && `(${player.role})`)),
            reminders,
            React.createElement("img", { className: "role-profile", src: image }),
            player.artifact && React.createElement("h3", null,
                "artifact: ",
                player.artifact.title),
            player.links && React.createElement("h3", null,
                "links to: ",
                player.links.join(', ')),
            player.copiedBy && React.createElement("h3", null,
                "copied by: ",
                player.copiedBy),
            children,
            React.createElement(tabs_1.Tabs, { actions: true },
                React.createElement("button", { onClick: onClick }, "close"))))));
};
