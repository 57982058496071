"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const cx = require("classnames");
exports.ArtifactView = ({ artifact, padded }) => (React.createElement("div", { className: cx('artifact', { padded }) },
    React.createElement("h2", null, artifact.title),
    React.createElement("div", null,
        React.createElement("strong", null, "use:"),
        " ",
        artifact.infinite ? '∞' : 1),
    React.createElement("div", null, artifact.description)));
