"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ramda_1 = require("ramda");
const roles_ts_1 = require("../interfaces/roles.ts");
exports.shuffle = (originalArray) => {
    let array = originalArray.slice(0);
    let currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
};
exports.updateFirst = (predicate, replacement, list) => {
    return ramda_1.update(ramda_1.findIndex(predicate, list), replacement, list);
};
exports.getDeckWeight = (deck) => ramda_1.reduce((totalWeight, card) => totalWeight + card.weight, 0, deck);
exports.getGameRoles = (game) => ramda_1.uniq(ramda_1.values(game.players)
    .map(player => player.role)
    .concat(game.initialRoles));
exports.getGameCards = (game) => exports.getGameRoles(game).map(roles_ts_1.getCard);
exports.getNumberOfARole = (role, deck) => ramda_1.reduce((acc, c) => {
    if (typeof c === 'string') {
        return c === role ? acc + 1 : acc;
    }
    else {
        return c.role === role ? acc + 1 : acc;
    }
}, 0, deck);
exports.isPlayerAlive = (game, name) => game.players[name].alive;
exports.removeFirst = (predicate, list) => ramda_1.remove(ramda_1.findIndex(predicate, list), 1, list);
exports.gameHasRole = (game, role) => exports.getGameRoles(game).indexOf(role) > -1;
exports.updatePlayerHash = (players, name, props) => (Object.assign({}, players, { [name]: Object.assign({}, players[name], (typeof props === 'function' ? props(players[name]) : props)) }));
exports.updatePlayer = (game, name, props) => (Object.assign({}, game, { players: Object.assign({}, game.players, { [name]: Object.assign({}, game.players[name], (typeof props === 'function' ? props(game.players[name]) : props)) }) }));
exports.addPrompt = (game, prompt) => (Object.assign({}, game, { prompts: prompt ? [...(game.prompts || []), prompt] : game.prompts || [] }));
exports.removePrompt = (game, message) => (Object.assign({}, game, { prompts: exports.removeFirst(ramda_1.propEq('message', message), game.prompts || []) }));
exports.comparePlayersFull = (a, b) => {
    const aName = a.name.toUpperCase();
    const bName = b.name.toUpperCase();
    if (a.alive && !b.alive)
        return -1;
    else if (!a.alive && b.alive)
        return 1;
    else if (aName < bName)
        return -1;
    else if (aName > bName)
        return 1;
    else
        return 0;
};
exports.comparePlayersName = (a, b) => {
    const aName = a.name.toUpperCase();
    const bName = b.name.toUpperCase();
    if (aName < bName)
        return -1;
    else if (aName > bName)
        return 1;
    else
        return 0;
};
exports.isNight = (game) => {
    return (!!(game.nightPrompts || []).length ||
        !!(game.prompts || []).filter(p => p.nightPrompt).length);
};
const firebase_ts_1 = require("./firebase.ts");
const ramda_2 = require("ramda");
exports.addDeck = (rawRoles, rawDecks) => {
    const roles = rawRoles.slice(0).sort();
    let found = false;
    const decks = rawDecks.map(deck => {
        if (ramda_2.equals(deck.roles.slice().sort(), roles)) {
            found = true;
            return Object.assign({}, deck, { timesPlayed: deck.timesPlayed + 1 });
        }
        return deck;
    });
    if (found) {
        decks.sort((a, b) => b.timesPlayed - a.timesPlayed);
        return firebase_ts_1.updateFirebase({ previousDecks: decks });
    }
    firebase_ts_1.updateFirebase({
        previousDecks: decks.concat({
            roles,
            timesPlayed: 1,
            id: Math.random().toString(),
        }),
    });
};
