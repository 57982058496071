"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ramda_1 = require("ramda");
const button_tsx_1 = require("../../../components/button.tsx");
const firebase_ts_1 = require("../../../helpers/firebase.ts");
const layout_tsx_1 = require("../../../components/layout.tsx");
const local = require("../../../helpers/localstorage.ts");
exports.ProveDead = ({ game }) => {
    const login = (playerName) => __awaiter(this, void 0, void 0, function* () {
        local.deadUsername.set({
            passcode: game.passcode,
            playerName,
        });
        firebase_ts_1.updateFirebase({
            game: Object.assign({}, game, { loggedInDeadPlayers: Object.assign({}, (game.loggedInDeadPlayers || {}), { [playerName]: true }) }),
        });
    });
    const eligablePlayers = ramda_1.values(game.players)
        .filter(player => !player.alive)
        .filter(player => !(game.loggedInDeadPlayers || {})[player.name]);
    return (React.createElement(layout_tsx_1.Content, { className: "prove-dead" },
        React.createElement("h1", null, "Who are you?"),
        React.createElement("div", null, "Only eliminated players can do this"),
        eligablePlayers.map(player => (React.createElement(button_tsx_1.Button, { confirm: `Confirm that you are ${player.name}`, onClick: () => login(player.name), key: player.name }, player.name)))));
};
