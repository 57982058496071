"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const cx = require("classnames");
const index_ts_1 = require("../../helpers/index.ts");
const weight_tsx_1 = require("../../components/weight.tsx");
exports.CardRow = ({ card, deck = [], children, onAdd, hideWeight, }) => {
    const numberInDeck = index_ts_1.getNumberOfARole(card.role, deck);
    return (React.createElement("button", { className: "card", onClick: onAdd },
        React.createElement("div", { className: "profile-container" },
            React.createElement("img", { className: cx('role-profile', { dim: numberInDeck === 0 }), src: card.image }),
            !!numberInDeck &&
                numberInDeck > 1 && React.createElement("div", { className: "count white" }, numberInDeck)),
        React.createElement("h2", null, card.role),
        !hideWeight && (React.createElement("h2", null,
            React.createElement(weight_tsx_1.Weight, { weight: card.weight })))));
};
