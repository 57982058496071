"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const cx = require("classnames");
const tabs_tsx_1 = require("../../components/tabs.tsx");
const button_tsx_1 = require("../../components/button.tsx");
const firebase_ts_1 = require("../../helpers/firebase.ts");
const layout_tsx_1 = require("../../components/layout.tsx");
const artifacts_ts_1 = require("../../interfaces/artifacts.ts");
const artifact_tsx_1 = require("../../components/artifact.tsx");
const ramda_1 = require("ramda");
class ManageArtifacts extends React.Component {
    render() {
        const isActive = (artifact) => !!this.props.artifacts.find(a => a.title === artifact.title);
        return (React.createElement(layout_tsx_1.Content, null,
            ramda_1.sortBy(a => a.title, artifacts_ts_1.AllArtifacts).map(artifact => (React.createElement("button", { className: cx('artifact', { active: isActive(artifact) }), key: artifact.title, onClick: () => {
                    if (isActive(artifact)) {
                        firebase_ts_1.updateFirebase({
                            artifacts: this.props.artifacts.filter(a => a.title !== artifact.title),
                        });
                    }
                    else {
                        firebase_ts_1.updateFirebase({
                            artifacts: this.props.artifacts.concat(artifact),
                        });
                    }
                } },
                React.createElement(artifact_tsx_1.ArtifactView, { artifact: artifact })))),
            React.createElement(tabs_tsx_1.Tabs, { actions: true },
                React.createElement(button_tsx_1.Button, { confirm: true, className: "red", disabled: !this.props.artifacts.length, onClick: () => firebase_ts_1.updateFirebase({ artifacts: [] }) }, "reset artifacts"))));
    }
}
exports.ManageArtifacts = ManageArtifacts;
