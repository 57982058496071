"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const roles_ts_1 = require("../interfaces/roles.ts");
const index_ts_1 = require("../helpers/index.ts");
const tabs_tsx_1 = require("../components/tabs.tsx");
const layout_tsx_1 = require("../components/layout.tsx");
const weight_tsx_1 = require("../components/weight.tsx");
const button_tsx_1 = require("../components/button.tsx");
const firebase_ts_1 = require("../helpers/firebase.ts");
const ramda_1 = require("ramda");
exports.Overview = ({ players, roles, noFlip, timeLimit, leaveLobby, lobbyId, }) => (React.createElement(layout_tsx_1.Content, null,
    React.createElement("h1", null,
        "Lobby: ",
        lobbyId),
    React.createElement("div", null,
        React.createElement("button", null)),
    React.createElement("h1", null, "options:"),
    React.createElement("ul", null,
        React.createElement("li", null,
            "Day time limit: ",
            timeLimit),
        React.createElement("li", null,
            "Flips card on death: ",
            noFlip ? 'no flip' : 'flip')),
    React.createElement("h1", null,
        "players: ",
        players.length),
    React.createElement("ul", null, players.map(player => (React.createElement("li", { key: player.name }, player.name)))),
    React.createElement("h1", null,
        "deck: cards: ",
        roles.length,
        ", weight:",
        ' ',
        React.createElement(weight_tsx_1.Weight, { weight: index_ts_1.getDeckWeight(roles.map(roles_ts_1.getCard)) })),
    React.createElement("ul", null, ramda_1.uniq(roles).map(role => (React.createElement("li", { key: role },
        role,
        " @ ",
        index_ts_1.getNumberOfARole(role, roles.map(roles_ts_1.getCard)))))),
    React.createElement(tabs_tsx_1.Tabs, { actions: true },
        React.createElement(button_tsx_1.Button, { confirm: true, className: "red", onClick: leaveLobby }, "leave lobby"),
        React.createElement(button_tsx_1.Button, { confirm: true, className: "red", disabled: !players.length && !roles.length, onClick: () => firebase_ts_1.updateFirebase(firebase_ts_1.defaultFirebaseState) }, "reset everything"))));
