"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const roles_ts_1 = require("../../interfaces/roles.ts");
const index_ts_1 = require("../../helpers/index.ts");
const card_tsx_1 = require("./card.tsx");
const tabs_tsx_1 = require("../../components/tabs.tsx");
const grid_tsx_1 = require("../../components/grid.tsx");
const button_tsx_1 = require("../../components/button.tsx");
const firebase_ts_1 = require("../../helpers/firebase.ts");
const layout_tsx_1 = require("../../components/layout.tsx");
const ramda_1 = require("ramda");
const weight_tsx_1 = require("../../components/weight.tsx");
class BuildDeck extends React.Component {
    constructor() {
        super(...arguments);
        this.renderCard = (card) => (React.createElement(card_tsx_1.CardRow, { deck: this.props.cards, card: card, id: card.role, key: card.role, onAdd: () => {
                if (card.cardCount > index_ts_1.getNumberOfARole(card.role, this.props.cards)) {
                    firebase_ts_1.updateFirebase({
                        roles: this.props.cards.concat(card).map(card => card.role),
                    });
                }
                else {
                    firebase_ts_1.updateFirebase({
                        roles: this.props.cards
                            .map(card => card.role)
                            .filter(role => role !== card.role),
                    });
                }
            } }));
    }
    render() {
        return (React.createElement(layout_tsx_1.Content, null,
            React.createElement("h1", null, "positive"),
            React.createElement(grid_tsx_1.Grid, null, roles_ts_1.AllCards.filter(c => c.weight >= 0)
                .sort((a, b) => a.weight - b.weight)
                .map(this.renderCard)),
            React.createElement("h1", null, "negative"),
            React.createElement(grid_tsx_1.Grid, null, roles_ts_1.AllCards.filter(c => c.weight < 0)
                .sort((a, b) => b.weight - a.weight)
                .map(this.renderCard)),
            !!this.props.previousDecks.length && (React.createElement(React.Fragment, null,
                React.createElement("h1", null, "previous decks"),
                this.props.previousDecks.map(deck => (React.createElement("div", { className: "deck", key: deck.id },
                    deck.title && React.createElement("h2", null, deck.title),
                    React.createElement("div", { className: "roles" }, ramda_1.groupWith(ramda_1.equals, deck.roles).map(roles => (React.createElement("div", { className: "role", key: roles[0] },
                        React.createElement("img", { className: "role-profile", src: roles_ts_1.getCard(roles[0]).profile }),
                        roles.length > 1 && (React.createElement("div", { className: "count white" }, roles.length)))))),
                    React.createElement("div", { className: "meta" },
                        React.createElement("span", null,
                            React.createElement("strong", null, "played"),
                            ": ",
                            deck.timesPlayed),
                        React.createElement("span", null,
                            React.createElement("strong", null, "cards"),
                            ": ",
                            deck.roles.length),
                        React.createElement("span", null,
                            React.createElement("strong", null, "weight"),
                            ":",
                            ' ',
                            React.createElement(weight_tsx_1.Weight, { weight: index_ts_1.getDeckWeight(deck.roles.map(roles_ts_1.getCard)) }))),
                    deck.description && React.createElement("div", null, deck.description),
                    React.createElement(tabs_tsx_1.Tabs, null,
                        React.createElement(button_tsx_1.Button, { confirm: true, className: "red", onClick: () => firebase_ts_1.updateFirebase({
                                previousDecks: index_ts_1.removeFirst(d => deck.id === d.id, this.props.previousDecks),
                            }) }, "remove"),
                        React.createElement(button_tsx_1.Button, { onClick: () => firebase_ts_1.updateFirebase({ roles: deck.roles }) }, "use"))))))),
            React.createElement(tabs_tsx_1.Tabs, { actions: true },
                React.createElement(button_tsx_1.Button, { confirm: true, className: "red", disabled: !this.props.cards.length, onClick: () => firebase_ts_1.updateFirebase({ roles: [] }) }, "reset deck"))));
    }
}
exports.BuildDeck = BuildDeck;
