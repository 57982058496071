"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ramda_1 = require("ramda");
exports.roleToSetupAction = (role, roleCount) => {
    const placeholder = (text) => `(${text || '____'})`;
    switch (role) {
        case 'direwolf':
            return {
                role,
                requiredPlayers: 1,
                players: [],
                requiredTargets: 1,
                targets: [],
                done: false,
                individualMessage: targets => (React.createElement(React.Fragment, null,
                    "You are a ",
                    role,
                    ". Choose someone ",
                    placeholder(targets[0]),
                    ", when they die, you die as well.")),
                moderatorMessage: (players, targets) => (React.createElement(React.Fragment, null,
                    role,
                    ", wake up and look at me ",
                    placeholder(players[0]),
                    ". Point at someone ",
                    placeholder(targets[0]),
                    ", when they die you die.")),
                allyMessage: targets => (React.createElement(React.Fragment, null,
                    "I am linked to ",
                    placeholder(targets[0]),
                    ". If they die then I do too.")),
            };
        case 'va wolf':
            return {
                role,
                requiredPlayers: 1,
                players: [],
                requiredTargets: 1,
                targets: [],
                done: false,
                individualMessage: targets => (React.createElement(React.Fragment, null,
                    "You are ",
                    role,
                    ". Choose someone, ",
                    placeholder(targets[0]),
                    ", when you die, they die as well.")),
                moderatorMessage: (players, targets) => (React.createElement(React.Fragment, null,
                    role,
                    ", wake up and look at me ",
                    placeholder(players[0]),
                    ". Point at someone ",
                    placeholder(targets[0]),
                    ", when you die they die.")),
            };
        case 'doppleganger':
            return {
                role,
                requiredPlayers: 1,
                players: [],
                requiredTargets: 1,
                targets: [],
                done: false,
                individualMessage: targets => (React.createElement(React.Fragment, null,
                    "You are a ",
                    role,
                    ". Choose someone, ",
                    placeholder(targets[0]),
                    ", when they die you become their role.")),
                moderatorMessage: (players, targets) => (React.createElement(React.Fragment, null,
                    role,
                    ", wake up and look at me ",
                    placeholder(players[0]),
                    ". Point at someone ",
                    placeholder(targets[0]),
                    ", when they die you become their role.")),
            };
        case 'cupid':
            return {
                role,
                requiredPlayers: 1,
                players: [],
                requiredTargets: 2,
                targets: [],
                done: false,
                individualMessage: targets => (React.createElement(React.Fragment, null,
                    "You are ",
                    role,
                    ". Choose two people, ",
                    placeholder(targets[0]),
                    " and",
                    ' ',
                    targets[1],
                    ". When one player dies, the other dies too.")),
                moderatorMessage: (players, targets) => (React.createElement(React.Fragment, null,
                    role,
                    ", wake up and look at me ",
                    placeholder(players[0]),
                    ". Point at two people: ",
                    placeholder(targets[0]),
                    ", ",
                    placeholder(targets[1]),
                    ". When one dies the other dies too.")),
            };
        case 'hoodlum':
            return {
                role,
                requiredPlayers: 1,
                players: [],
                requiredTargets: 2,
                targets: [],
                done: false,
                individualMessage: targets => (React.createElement(React.Fragment, null,
                    "You are a ",
                    role,
                    ". Choose two people, ",
                    placeholder(targets[0]),
                    " and",
                    ' ',
                    placeholder(targets[1]),
                    ". You can only win with the villagers if both those players are dead.")),
                moderatorMessage: (players, targets) => (React.createElement(React.Fragment, null,
                    role,
                    ", wake up and look at me ",
                    placeholder(players[0]),
                    ". Point at two people: ",
                    placeholder(targets[0]),
                    ", ",
                    placeholder(targets[1]),
                    ". You can only win with the villagers if they both die.")),
            };
        case 'wolf cub':
        case 'mentalist':
        case 'boogyman':
        case 'mad bomber':
        case 'huntress':
        case 'teenage werewolf':
        case 'alpha wolf':
        case 'revealer':
        case 'wolf man':
        case 'seer':
        case 'apprentice seer':
        case 'bodyguard':
        case 'cursed':
        case 'cult leader':
        case 'hunter':
        case 'mason':
        case 'sorceress':
        case 'witch':
        case 'big bad wolf':
        case 'mayor':
        case 'tanner':
        case 'pi':
        case 'prince':
        case 'lycan':
        case 'aura seer':
        case 'minion':
        case 'priest':
        case 'vampire':
        case 'diseased':
        case 'old hag':
        case 'fang face':
        case 'fruit brute':
        case 'bloody marry':
        case 'pacifist':
        case 'werewolf':
        case 'spell caster':
        case 'village idiot':
        case 'chewks':
        case 'drunk':
            return {
                role,
                requiredPlayers: roleCount,
                players: [],
                requiredTargets: 0,
                targets: [],
                done: false,
                moderatorMessage: (players, targets) => {
                    const isPlural = roleCount > 1;
                    const playerNames = players
                        .map(placeholder)
                        .concat(ramda_1.repeat(placeholder(), roleCount))
                        .slice(0, roleCount)
                        .join(', ');
                    return (React.createElement(React.Fragment, null,
                        role,
                        isPlural ? 's' : '',
                        ", wake up and look at me: ",
                        playerNames));
                },
            };
        case 'villager':
            return {
                role,
                requiredPlayers: roleCount,
                players: [],
                requiredTargets: 0,
                targets: [],
                done: false,
            };
    }
};
