"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const layout_tsx_1 = require("../../components/layout.tsx");
const index_tsx_1 = require("./cards/index.tsx");
const selectRoles_1 = require("./remote/selectRoles");
const moderator_1 = require("./remote/moderator");
const firebase_ts_1 = require("../../helpers/firebase.ts");
class SetupGame extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { type: 'undecided', selectedRoles: this.props.roles };
        this.componentDidUpdate = (prevProps) => {
            if (prevProps.players.length !== this.props.players.length) {
                this.onCancel();
            }
        };
        this.clearPlayers = () => {
            firebase_ts_1.updateFirebase({
                players: this.props.players.map(p => (Object.assign({}, p, { remoteSetup: null, artifact: null, done: false, role: null }))),
            });
        };
        this.onCancel = () => {
            this.setState({ type: 'undecided' });
            this.clearPlayers();
        };
    }
    render() {
        const allPlayersClaimed = this.props.players.reduce((memo, player) => memo && player.claimed, true);
        // ==============================================
        // We are undecided, prompt how to setup the game
        // ==============================================
        if (this.state.type === 'undecided') {
            return (React.createElement(layout_tsx_1.Content, { className: "padded" },
                React.createElement("h1", null, "How would you like to set up the game?"),
                React.createElement("button", { disabled: !allPlayersClaimed, onClick: () => {
                        this.clearPlayers();
                        this.setState({ type: 'remote' });
                    } }, "remote setup"),
                React.createElement("button", { onClick: () => {
                        this.clearPlayers();
                        this.setState({ type: 'cards' });
                    } }, "card setup")));
        }
        // ==============================================
        // We are remote, make sure we have enough roles
        // ==============================================
        if (this.state.type === 'remote') {
            // If we have too many or too little roles, prompt the
            // moderator to accommodate more
            if (this.props.players.length !== this.state.selectedRoles.length) {
                return (React.createElement(selectRoles_1.SelectRoles, { cancel: this.onCancel, roles: this.state.selectedRoles, numberOfPlayers: this.props.players.length, onSubmit: selectedRoles => this.setState({
                        selectedRoles,
                        type: 'remote',
                    }) }));
            }
            // We have the perfect number of roles to players,
            // get right to the game setup
            else {
                return (React.createElement(moderator_1.RemoteSetup, { ghost: this.props.ghost, killCult: this.props.killCult, noFlip: this.props.noFlip, boogymanOP: this.props.boogymanOP, artifacts: this.props.artifacts, timeLimit: this.props.timeLimit, players: this.props.players, roles: this.state.selectedRoles, allRoles: this.props.roles, onCancel: this.onCancel }));
            }
        }
        // ==============================================
        // We are using cards, yolo
        // ==============================================
        if (this.state.type === 'cards') {
            return (React.createElement(index_tsx_1.SetupGame, { ghost: this.props.ghost, killCult: this.props.killCult, noFlip: this.props.noFlip, players: this.props.players, artifacts: this.props.artifacts, previousDecks: this.props.previousDecks, roles: this.state.selectedRoles, timeLimit: this.props.timeLimit, boogymanOP: this.props.boogymanOP }));
        }
    }
}
exports.SetupGame = SetupGame;
