"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const cx = require("classnames");
const button_tsx_1 = require("../../components/button.tsx");
const game_ts_1 = require("../../interfaces/game.ts");
const index_ts_1 = require("../../helpers/index.ts");
const roles_ts_1 = require("../../interfaces/roles.ts");
const firebase_ts_1 = require("../../helpers/firebase.ts");
const actions_ts_1 = require("../../interfaces/actions.ts");
const grid_tsx_1 = require("../../components/grid.tsx");
const ramda_1 = require("ramda");
const artifacts_ts_1 = require("../../interfaces/artifacts.ts");
exports.makeActionButton = (game, player, type, done) => {
    const action = actions_ts_1.Actions(type);
    const playerProp = 'playerProp' in action ? action.playerProp : null;
    const attr = playerProp && player && player[playerProp];
    return (React.createElement(button_tsx_1.Button, { key: type, className: cx({
            green: (type === 'protect' || type === 'bless') && !!attr,
            red: (type === 'bite' ||
                type === 'transform' ||
                type === 'indoctrinate') &&
                !!attr,
        }), onClick: () => {
            done(game_ts_1.performAction(game, player
                ? { type, target: player.name, playerProp: 'name' }
                : { type, target: null }));
        } }, type === 'kill'
        ? player && player.alive
            ? 'kill'
            : `revive`
        : type === 'sudo kill' || type === 'bypass protection'
            ? type
            : type === 'transform'
                ? type
                : attr
                    ? `un-${type}`
                    : type));
};
class ChangeRoleButton extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { open: false };
    }
    render() {
        const { game, done, player } = this.props;
        if (!player)
            return null;
        return (React.createElement(React.Fragment, null,
            React.createElement(button_tsx_1.Button, { onClick: () => this.setState({ open: !this.state.open }) }, this.state.open ? 'hide roles' : 'change role'),
            this.state.open && (React.createElement(grid_tsx_1.Grid, null, ramda_1.sortBy(c => c.role, roles_ts_1.AllCards).map(card => card.role === player.role ? null : (React.createElement(button_tsx_1.Button, { key: card.role, onClick: () => done(Object.assign({}, game, index_ts_1.updatePlayer(game, player.name, {
                    role: card.role,
                }), { activePlayer: null })) },
                React.createElement("img", { className: "role-profile", src: card.image }),
                card.role)))))));
    }
}
exports.makeGameButtons = (game, player) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(button_tsx_1.Button, { onClick: () => firebase_ts_1.updateFirebase({
                game: game_ts_1.performAction(game, {
                    type: 'kill',
                    target: player.name,
                    playerProp: 'name',
                }),
            }) }, player.alive ? 'kill' : 'revive'),
        React.createElement(button_tsx_1.Button, { onClick: () => {
                const artifactsInPlay = ramda_1.values(game.players).reduce((memo, p) => (p.artifact ? memo.concat(p.artifact) : memo), []);
                const artifacts = index_ts_1.shuffle(artifacts_ts_1.AllArtifacts).filter(a => !artifactsInPlay.find(aa => a.title === aa.title));
                firebase_ts_1.updateFirebase({
                    game: Object.assign({}, index_ts_1.updatePlayer(game, player.name, { artifact: artifacts[0] }), { activePlayer: null }),
                });
            } }, "give artifact"),
        player.artifact && (React.createElement(button_tsx_1.Button, { onClick: () => {
                firebase_ts_1.updateFirebase({
                    game: Object.assign({}, index_ts_1.updatePlayer(game, player.name, { artifact: null }), { activePlayer: null }),
                });
            } }, "destroy artifact")),
        player.alive &&
            index_ts_1.gameHasRole(game, roles_ts_1.Roles['spell caster']) &&
            exports.makeActionButton(game, player, 'silence', game => firebase_ts_1.updateFirebase({ game })),
        player.alive &&
            exports.makeActionButton(game, player, 'protect', game => firebase_ts_1.updateFirebase({ game })),
        player.alive &&
            index_ts_1.gameHasRole(game, 'vampire') &&
            exports.makeActionButton(game, player, 'bite', game => firebase_ts_1.updateFirebase({ game })),
        player.alive &&
            index_ts_1.gameHasRole(game, 'cult leader') &&
            exports.makeActionButton(game, player, 'indoctrinate', game => firebase_ts_1.updateFirebase({ game })),
        player.alive &&
            index_ts_1.gameHasRole(game, 'priest') &&
            exports.makeActionButton(game, player, 'bless', game => firebase_ts_1.updateFirebase({ game })),
        player.alive &&
            index_ts_1.gameHasRole(game, 'old hag') &&
            exports.makeActionButton(game, player, 'exile', game => firebase_ts_1.updateFirebase({ game })),
        player.alive &&
            (roles_ts_1.getCard(player.role).actions || []).map(type => (React.createElement(button_tsx_1.Button, { key: type, onClick: () => firebase_ts_1.updateFirebase({
                    game: game_ts_1.performAction(game, {
                        type,
                        target: player.name,
                        playerProp: 'name',
                    }),
                }) }, type))),
        player.alive && (React.createElement(ChangeRoleButton, { game: game, player: player, done: game => firebase_ts_1.updateFirebase({ game }) }))));
};
