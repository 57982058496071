"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const layout_tsx_1 = require("../../components/layout.tsx");
const roleDescription_tsx_1 = require("../../components/roleDescription.tsx");
const roles_ts_1 = require("../../interfaces/roles.ts");
const tabs_tsx_1 = require("../../components/tabs.tsx");
const ramda_1 = require("ramda");
const artifact_tsx_1 = require("../../components/artifact.tsx");
const grid_tsx_1 = require("../../components/grid.tsx");
const card_tsx_1 = require("../deck/card.tsx");
const button_tsx_1 = require("../../components/button.tsx");
class IndividualGame extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { view: { type: 'game' } };
        this.render = () => {
            return (React.createElement(layout_tsx_1.Content, null,
                React.createElement(tabs_tsx_1.Tabs, { navigation: true },
                    React.createElement("button", { onClick: () => this.setState({ view: { type: 'game' } }) }, "game stats"),
                    this.props.player.artifact && (React.createElement("button", { onClick: () => this.setState({ view: { type: 'artifact' } }) }, "artifact")),
                    React.createElement("button", { onClick: () => this.setState({ view: { type: 'role' } }) }, "my role")),
                this.props.player.artifact &&
                    this.state.view.type === 'artifact' && (React.createElement(artifact_tsx_1.ArtifactView, { artifact: this.props.player.artifact, padded: true })),
                this.state.view.type === 'role-info' && (React.createElement("div", { className: "padded" },
                    React.createElement(roleDescription_tsx_1.RoleDescription, { role: this.state.view.role }),
                    React.createElement(tabs_tsx_1.Tabs, null,
                        React.createElement(button_tsx_1.Button, { className: "red", onClick: () => this.setState({ view: { type: 'game' } }) }, "Done")))),
                this.state.view.type === 'game' && (React.createElement("div", { className: "padded" },
                    React.createElement("h1", null, "Living Players:"),
                    ramda_1.values(this.props.game.players)
                        .filter(player => player.alive && player.name !== this.props.player.name)
                        .sort()
                        .map(player => (React.createElement("div", { key: player.name }, player.name))),
                    React.createElement("h1", null, "Possible roles in the game (tap to read more):"),
                    React.createElement(grid_tsx_1.Grid, null, ramda_1.uniq(this.props.game.initialRoles)
                        .map(role => roles_ts_1.getCard(role))
                        .sort((a, b) => b.weight - a.weight)
                        .map(card => (React.createElement(card_tsx_1.CardRow, { hideWeight: true, card: card, deck: this.props.game.initialRoles.map(roles_ts_1.getCard), onAdd: () => {
                            this.setState({
                                view: { type: 'role-info', role: card.role },
                            });
                        } })))))),
                this.state.view.type === 'role' && (React.createElement(roleDescription_tsx_1.RoleDescription, { role: this.props.role }))));
        };
    }
}
exports.IndividualGame = IndividualGame;
