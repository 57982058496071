"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const roles_ts_1 = require("./roles.ts");
const index_ts_1 = require("../helpers/index.ts");
const ramda_1 = require("ramda");
exports.defaultGame = {
    passcode: '',
    initialRoles: [],
    players: {},
    options: {
        noFlip: false,
        timeLimit: 0,
        ghost: false,
        killCult: false,
        boogymanOP: false,
    },
    prompts: [],
    nightPrompts: [],
    nightKills: [],
    activePlayer: null,
    dayCount: 0,
    loggedInDeadPlayers: {},
    chat: [],
    ghost: {},
};
const makeIndoctrinatePrompt = (game) => {
    if (ramda_1.values(game.players)
        .filter(p => p.role !== roles_ts_1.Roles['cult leader'])
        .filter(p => p.alive)
        .every(p => p.indoctrinated)) {
        game = index_ts_1.addPrompt(game, {
            message: 'All the living players are in the cult, the cult leader wins!',
        });
    }
    return game;
};
const makeFruitBrutePrompt = (game) => {
    const livingWolves = ramda_1.values(game.players)
        .filter(p => p.alive)
        .filter(p => roles_ts_1.getCard(p.role).team === 'wolf');
    if (livingWolves.length &&
        livingWolves.every(p => p.role === roles_ts_1.Roles['fruit brute'] || p.role === roles_ts_1.Roles['fang face'])) {
        game = index_ts_1.addPrompt(game, {
            message: 'The fruit brute is currently the only werewolf active, they can not kill anyone.',
        });
    }
    return game;
};
exports.preDeathAction = (player, action) => {
    const role = roles_ts_1.getCard(player.role);
    if (action === 'sudo kill')
        return null;
    if (action === 'kill' && (player.protected || player.blessed)) {
        const typeSpecificAction = player.protected ? 'protect' : 'bless';
        return {
            target: player.name,
            message: `${player.name} is protected, what would you like to do?`,
            actions: [typeSpecificAction, 'bypass protection'],
        };
    }
    if (role.preDeathAction) {
        return role.preDeathAction(player);
    }
    return null;
};
exports.performAction = (cleanGame, action) => {
    let game = Object.assign({}, cleanGame, { activePlayer: null });
    const player = action.target ? game.players[action.target] : null;
    switch (action.type) {
        // In the order of precedence
        case 'kill':
        case 'bypass protection':
        case 'sudo kill':
            if (!player)
                return game;
            const role = roles_ts_1.getCard(player.role);
            // Revive the player
            if (!player.alive) {
                if (player.copiedBy) {
                    game = index_ts_1.updatePlayer(game, player.copiedBy, {
                        role: 'doppleganger',
                    });
                }
                if (player.role === 'cursed') {
                    game = index_ts_1.updatePlayer(game, player.name, { role: 'cursed' });
                }
                game = Object.assign({}, game, { nightKills: (game.nightKills || []).filter(name => name !== player.name) });
                return index_ts_1.updatePlayer(game, player.name, {
                    alive: true,
                });
            }
            // Attempt to kill the player if they don't have any pre-death prompts
            const rolesPreDeathAction = exports.preDeathAction(player, action.type);
            if (rolesPreDeathAction) {
                return index_ts_1.addPrompt(game, rolesPreDeathAction);
            }
            // Before fully killing the player we need to...
            // Perform cleanup
            if (player.copiedBy) {
                game = index_ts_1.updatePlayer(game, player.copiedBy, {
                    role: player.role,
                });
                game = index_ts_1.addPrompt(game, {
                    message: `${player.copiedBy} transformed into a ${player.role} since ${player.name} was killed`,
                });
            }
            let cultMembers = [];
            if (player.role === 'cult leader' && game.options.killCult)
                cultMembers = ramda_1.values(game.players)
                    .filter(p => p.indoctrinated)
                    .map(p => p.name);
            // Add prompts to kill linked player
            game = (player.links || [])
                .concat(cultMembers)
                .filter(name => index_ts_1.isPlayerAlive(game, name))
                .reduce((game, linkedPlayer) => {
                return index_ts_1.addPrompt(game, {
                    message: `${player.name} has died and was linked to ${linkedPlayer}`,
                    actions: ['kill'],
                    target: linkedPlayer,
                });
            }, game);
            // Add any prompts for when specific roles die
            if (role.deathMessage) {
                game = index_ts_1.addPrompt(game, { message: role.deathMessage });
            }
            // Update what players have been killed this night
            game = Object.assign({}, game, { nightKills: (game.nightKills || []).concat(player.name) });
            // Kill the player
            game = index_ts_1.updatePlayer(game, player.name, { alive: false });
            game = makeFruitBrutePrompt(game);
            game = makeIndoctrinatePrompt(game);
            return game;
        case 'bless':
            return player
                ? index_ts_1.updatePlayer(game, player.name, { blessed: !player.blessed })
                : game;
        case 'protect':
            return player
                ? index_ts_1.updatePlayer(game, player.name, { protected: !player.protected })
                : game;
        case 'bite':
            return player
                ? index_ts_1.updatePlayer(game, player.name, { bitten: !player.bitten })
                : game;
        case 'silence':
            return player
                ? index_ts_1.updatePlayer(game, player.name, { silenced: !player.silenced })
                : game;
        case 'exile':
            return player
                ? index_ts_1.updatePlayer(game, player.name, { exiled: !player.exiled })
                : game;
        case 'indoctrinate':
            game = player
                ? index_ts_1.updatePlayer(game, player.name, {
                    indoctrinated: !player.indoctrinated,
                })
                : game;
            game = makeIndoctrinatePrompt(game);
            return game;
        case 'transform':
            return player
                ? index_ts_1.updatePlayer(game, player.name, {
                    role: player.role === 'werewolf' ? 'cursed' : 'werewolf',
                })
                : game;
        case 'next role':
            game = Object.assign({}, game, { prompts: index_ts_1.removeFirst(p => !!p.nightPrompt, game.prompts || []) });
            game = index_ts_1.addPrompt(game, (game.nightPrompts || [])[0]);
            game = Object.assign({}, game, { nightPrompts: (game.nightPrompts || []).slice(1) });
            game = Object.assign({}, game, { dayCount: index_ts_1.isNight(game) ? game.dayCount : game.dayCount + 1 });
            return game;
    }
};
// Sometimes we have extra roles, if we have an extra role that isn't
// in the game, the mod should bluff that the role is in the game still
exports.isRoleOmitted = (game, role) => {
    return ramda_1.contains(role, ramda_1.difference(game.initialRoles, ramda_1.values(game.players).map(p => p.role)));
};
exports.isRoleAlive = (game, role) => {
    return ramda_1.contains(role, ramda_1.values(game.players)
        .filter(p => p.alive)
        .map(p => p.role));
};
exports.isRoleActive = (game, role) => {
    // The apprentice seer is active only when the seer isn't alive
    if (role === 'apprentice seer' &&
        !exports.isRoleAlive(game, 'seer') &&
        exports.isRoleAlive(game, 'apprentice seer')) {
        return true;
    }
    // bloody marry is active only when she is dead
    if (role === 'bloody marry') {
        return !exports.isRoleAlive(game, 'bloody marry');
    }
    // If the role is omitted then fake it
    if (exports.isRoleOmitted(game, role)) {
        return true;
    }
    return exports.isRoleAlive(game, role);
};
