"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function localItem(key) {
    return {
        get() {
            return JSON.parse(localStorage.getItem(key) || 'null');
        },
        set(val) {
            localStorage.setItem(key, JSON.stringify(val));
        },
    };
}
exports.passCode = localItem('ww-werewolf-pass-code');
exports.lobbyId = localItem('wt-werewolf-lobby');
exports.player = localItem('wt-werewolf-player');
exports.deadUsername = localItem('wt-werewolf-dead-username');
