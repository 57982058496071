"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ramda_1 = require("ramda");
const roles_ts_1 = require("../interfaces/roles.ts");
const tabs_tsx_1 = require("../components/tabs.tsx");
const layout_tsx_1 = require("../components/layout.tsx");
const button_tsx_1 = require("../components/button.tsx");
const firebase_ts_1 = require("../helpers/firebase.ts");
const local = require("../helpers/localstorage.ts");
const grid_tsx_1 = require("../components/grid.tsx");
const individual_tsx_1 = require("./setup/remote/individual.tsx");
const individual_tsx_2 = require("./game/individual.tsx");
const card_tsx_1 = require("./deck/card.tsx");
const roleDescription_tsx_1 = require("../components/roleDescription.tsx");
class IndividualLobby extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { showRole: null };
    }
    render() {
        if (this.props.player.role && this.props.game) {
            return (React.createElement(individual_tsx_2.IndividualGame, { game: this.props.game, player: this.props.game.players[this.props.player.name], role: this.props.game.players[this.props.player.name].role }));
        }
        if (this.props.player.role && this.props.player.remoteSetup) {
            return (React.createElement(individual_tsx_1.IndividualSetup, { player: this.props.player, players: this.props.players, setup: this.props.player.remoteSetup, role: this.props.player.role, roles: this.props.roles }));
        }
        return (React.createElement(layout_tsx_1.Content, null,
            React.createElement(tabs_tsx_1.Tabs, { navigation: true },
                React.createElement("h1", null,
                    "Lobby: ",
                    this.props.lobbyId),
                React.createElement("h1", null,
                    "Player: ",
                    this.props.player.name)),
            this.state.showRole && React.createElement(roleDescription_tsx_1.RoleDescription, { role: this.state.showRole }),
            !this.state.showRole && (React.createElement(React.Fragment, null,
                React.createElement("h1", null, "Roles in the game (tap to read more):"),
                React.createElement(grid_tsx_1.Grid, null, ramda_1.uniq(this.props.roles)
                    .map(role => roles_ts_1.getCard(role))
                    .sort((a, b) => b.weight - a.weight)
                    .map(card => (React.createElement(card_tsx_1.CardRow, { hideWeight: true, card: card, deck: this.props.roles.map(roles_ts_1.getCard), onAdd: () => {
                        this.setState({ showRole: card.role });
                    } })))))),
            React.createElement(tabs_tsx_1.Tabs, { actions: true },
                this.state.showRole && (React.createElement(button_tsx_1.Button, { className: "red", onClick: () => this.setState({ showRole: null }) }, "Show all roles")),
                !this.state.showRole && (React.createElement(button_tsx_1.Button, { confirm: "Are you sure you want to leave?", onClick: () => {
                        firebase_ts_1.updateFirebase({
                            players: this.props.players.filter(p => p.name !== this.props.player.name),
                        });
                        local.player.set(null);
                        this.props.leaveLobby();
                    } }, "leave")))));
    }
}
exports.IndividualLobby = IndividualLobby;
