"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
exports.wolfSetupPrompt = {
    role: 'werewolf',
    requiredPlayers: 0,
    players: [],
    requiredTargets: 0,
    targets: [],
    done: false,
    individualMessage: () => null,
    moderatorMessage: (players, target) => {
        return React.createElement(React.Fragment, null, "Wolves wake up and look at me");
    },
};
exports.wolfSetupSleep = {
    role: 'werewolf',
    requiredPlayers: 0,
    players: [],
    requiredTargets: 0,
    targets: [],
    done: false,
    individualMessage: () => null,
    moderatorMessage: (players, target) => {
        return React.createElement(React.Fragment, null, "Wolves go back to sleep");
    },
};
