"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const game_ts_1 = require("../../../interfaces/game.ts");
const roles_ts_1 = require("../../../interfaces/roles.ts");
const index_ts_1 = require("../../../helpers/index.ts");
const ramda_1 = require("ramda");
const tabs_tsx_1 = require("../../../components/tabs.tsx");
const grid_tsx_1 = require("../../../components/grid.tsx");
const button_tsx_1 = require("../../../components/button.tsx");
const player_ts_1 = require("../../../interfaces/player.ts");
const firebase_ts_1 = require("../../../helpers/firebase.ts");
const layout_tsx_1 = require("../../../components/layout.tsx");
const setupPlayer_tsx_1 = require("../../../components/setupPlayer.tsx");
const local = require("../../../helpers/localstorage.ts");
const performSetupAction_ts_1 = require("../performSetupAction.ts");
const prompts_tsx_1 = require("../prompts.tsx");
const roleToSetupAction_tsx_1 = require("../roleToSetupAction.tsx");
const cardsToActions = (cards, roles) => {
    return cards
        .map(({ role }) => {
        return roleToSetupAction_tsx_1.roleToSetupAction(role, index_ts_1.getNumberOfARole(role, roles));
    })
        .filter(x => !!x);
};
class SetupGame extends React.Component {
    constructor(props) {
        super(props);
        const cards = ramda_1.uniq(props.roles).map(roles_ts_1.getCard);
        const sortedCards = ramda_1.sortBy(card => card.team, cards);
        const [wolves, villagers] = ramda_1.partition((card) => {
            return card.team === 'wolf';
        }, sortedCards);
        const villageActions = cardsToActions(villagers, props.roles);
        const werewolfActions = cardsToActions(wolves, props.roles);
        const wolfBreak = werewolfActions.length > 1 ? [prompts_tsx_1.wolfSetupPrompt] : [];
        const villagerBreak = werewolfActions.length > 1 ? [prompts_tsx_1.wolfSetupSleep] : [];
        const actions = [
            ...wolfBreak,
            ...werewolfActions,
            ...villagerBreak,
            ...villageActions,
        ];
        this.state = {
            actionsRemaining: actions.slice(1),
            currentAction: actions[0],
            game: Object.assign({}, game_ts_1.defaultGame, { initialRoles: props.roles, players: props.players.reduce((memo, player) => (Object.assign({}, memo, { [player.name]: player })), {}), options: {
                    timeLimit: props.timeLimit,
                    noFlip: props.noFlip,
                    boogymanOP: props.boogymanOP,
                    ghost: props.ghost,
                    killCult: props.killCult,
                } }),
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.currentAction && !ramda_1.equals(this.state, prevState)) {
            // Assign drunk
            const randomRole = roles_ts_1.Roles[Math.floor(Math.random() * roles_ts_1.Roles.length)];
            // prettier-ignore
            const players = ramda_1.map(player => player.role === 'drunk' ? Object.assign({}, player, { role: randomRole, drunk: true }) : player, this.state.game.players);
            this.setState({ game: Object.assign({}, this.state.game, { players }) }, () => {
                // Protect game with passcode
                const passcode = Math.floor(Math.random() * 10000).toString();
                local.passCode.set(passcode);
                firebase_ts_1.updateFirebase({
                    game: Object.assign({}, this.state.game, { passcode, players: ramda_1.map(player => (Object.assign({}, player_ts_1.defaultPlayer, { role: 'villager' }, player)), this.state.game.players) }),
                });
            });
            index_ts_1.addDeck(this.props.roles, this.props.previousDecks);
        }
    }
    render() {
        const { game, currentAction } = this.state;
        if (!currentAction || !currentAction.moderatorMessage)
            return null;
        return (React.createElement(layout_tsx_1.Content, null,
            React.createElement("h1", { className: "prompt" },
                roles_ts_1.getCard(currentAction.role).emoji,
                ' ',
                currentAction.moderatorMessage(currentAction.players, currentAction.targets),
                ' ',
                roles_ts_1.getCard(currentAction.role).emoji),
            React.createElement(grid_tsx_1.Grid, null, ramda_1.values(game.players)
                .sort(index_ts_1.comparePlayersName)
                .map(player => {
                return (React.createElement(setupPlayer_tsx_1.PlayerSetupRow, { player: {
                        pin: player.pin,
                        claimed: player.claimed,
                        remoteSetup: player.remoteSetup,
                        artifact: null,
                        name: player.name,
                        role: currentAction.players.find(ramda_1.equals(player.name))
                            ? currentAction.role
                            : player.role,
                    }, key: player.name, onClick: () => {
                        let { targets, players } = currentAction;
                        if (!!players.find(ramda_1.equals(player.name))) {
                            // Player already is the role
                            players = ramda_1.reject(ramda_1.equals(player.name), players);
                        }
                        else if (players.length < currentAction.requiredPlayers) {
                            if (!player.role) {
                                // We need more roles and the player needs one too
                                players = ramda_1.append(player.name, players);
                                if (!!targets.find(ramda_1.equals(player.name))) {
                                    targets = ramda_1.reject(ramda_1.equals(player.name), targets);
                                }
                            }
                        }
                        else if (!!targets.find(ramda_1.equals(player.name))) {
                            // The player is already a target
                            targets = ramda_1.reject(ramda_1.equals(player.name), targets);
                        }
                        else if (targets.length < currentAction.requiredTargets) {
                            // There are targets left to be made
                            targets = ramda_1.append(player.name, targets);
                        }
                        this.setState({
                            currentAction: Object.assign({}, currentAction, { targets,
                                players }),
                        });
                    } }));
            })),
            React.createElement(tabs_tsx_1.Tabs, { actions: true },
                React.createElement(button_tsx_1.Button, { className: "red", confirm: true, onClick: () => {
                        this.setState({
                            currentAction: this.state.actionsRemaining[0],
                            actionsRemaining: this.state.actionsRemaining.slice(1),
                        });
                    } }, "skip"),
                React.createElement(button_tsx_1.Button, { disabled: (currentAction.requiredPlayers &&
                        !currentAction.players.length) ||
                        currentAction.targets.length !== currentAction.requiredTargets, confirm: !(currentAction.players.length === currentAction.requiredPlayers)
                        ? 'u sure? unset players remain'
                        : false, onClick: () => {
                        this.setState({
                            game: Object.assign({}, this.state.game, { players: performSetupAction_ts_1.performSetupAction(this.state.game.players, currentAction) }),
                            currentAction: this.state.actionsRemaining[0],
                            actionsRemaining: this.state.actionsRemaining.slice(1),
                        });
                    } }, "next"))));
    }
}
exports.SetupGame = SetupGame;
