"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const roles_ts_1 = require("../interfaces/roles.ts");
exports.RoleDescription = ({ role }) => {
    const card = roles_ts_1.getCard(role);
    return (React.createElement("div", { className: "role-description padded" },
        React.createElement("h1", null,
            card.emoji,
            " Role: ",
            role,
            " ",
            card.emoji),
        React.createElement("img", { src: card.image }),
        React.createElement("h2", null, "Description:"),
        React.createElement("p", null, card.description),
        card.hints &&
            !!card.hints.length && (React.createElement(React.Fragment, null,
            React.createElement("h2", null, "Hints:"),
            React.createElement("ul", null, card.hints.map((hint, i) => (React.createElement("li", { key: i }, hint))))))));
};
