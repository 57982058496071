"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const firebase_ts_1 = require("../../../helpers/firebase.ts");
const layout_tsx_1 = require("../../../components/layout.tsx");
const index_ts_1 = require("../../../helpers/index.ts");
class Ghost extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    render() {
        const { game } = this.props;
        const letters = 'abcdefghijklmnopqrstuvwxyz'.split('');
        return (React.createElement(layout_tsx_1.Content, { className: "ghost" },
            React.createElement("h1", null, "Ghost"),
            React.createElement("div", null, index_ts_1.isNight(game)
                ? 'Tap (as many times) on the letter you want. The letter with the most votes wins.'
                : 'Voting begins at night'),
            index_ts_1.isNight(game) && (React.createElement("div", null,
                React.createElement("strong", null, "Roles remaining:"),
                ' ',
                (game.nightPrompts || []).length + 1)),
            React.createElement("div", { className: "ghost-buttons" }, letters.map(letter => {
                const length = Object.keys((game.ghost || {})[letter] || []).length;
                return (React.createElement("button", { disabled: !game.options.ghost || !index_ts_1.isNight(game), style: { fontSize: `${1 + length / 50}em` }, key: letter, onClick: () => firebase_ts_1.ghostVote(letter) }, letter));
            }))));
    }
}
exports.Ghost = Ghost;
